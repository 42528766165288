import {createStore} from "vuex";

const store = createStore({
    state(){
        return{
            id:1,
            userId:"",
            nickname:"",
            loginTime:"",
            role:[]
        }
    },
    mutations:{
        //设置用户id
        setUserId(state,val){
            state.userId = val;
        },
        //设置用户名称
        setNickame(state,val){
            state.nickname = val;
        },
        //设置角色
        setRole(state,val){
            state.role = val;
        },
        //记录登录时间
        setLoginTime(state,val){
            state.loginTime = val;
        }
    }
})

export default store;