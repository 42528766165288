<template>
    <div class="switch" :class="{switchActive:active}" @click="changeStatus()">
        <div class="switch-box" :class="{boxActive:active}">
            <span class="iconfont text-12" :class="[active ? 'i-ok' : 'i-close',active ? 'blue' : 'l-gray']"></span>
        </div>
    </div>
</template>
<script>
export default {
    name:"y-switch",
    props:{
        status:{
            default:1,
            type:Number
        }
    },
    data(){
        return{
            active:true
        }
    },
    mounted(){
        this.active = this.status === 1 ? true : false;
    },
    updated(){
        this.active = this.status === 1 ? true : false;
    },
    methods:{
        //改变状态
        changeStatus(){
            this.active = !this.active;
            this.$emit("change",{
                status:this.active
            })
        }
    }
}
</script>
<style scoped>
    .switch{
        position:relative;
        width:49px;
        height:24px;
        border-radius:13px;
        background-color:#ededed;
        box-shadow:0 0 8px 3px rgba(0,0,0,.08) inset;
        transition:all .3s;
        cursor:pointer;
    }
    .switchActive{
        background-color:#6c97f5;
        color:#fff;
    }
    .switch-box{
        position:absolute;
        top:2px;
        left:27px;
        width:20px;
        height:20px;
        background-color:#fff;
        border-radius:10px;
        text-align:center;
        line-height:20px;
        transition:all .3s;
    }
    .boxActive{
        left:2px;
        transform:rotate(-360deg);
    }
</style>