import { createRouter, createWebHistory } from 'vue-router';

import Login from "../page/user/login.vue";
import User from "@page/user/user.vue";
import Building from "@page/user/building.vue";
import House from "@page/user/house.vue";
import Group from "@page/user/group.vue";
import Gv from "@page/admin/gv.vue";
import Village from "@page/admin/village.vue";
import VillageAdmin from "@page/admin/villageAdmin.vue";
import Guard from "@page/device/guard.vue";
import OpenRecord from "@page/record/openRecord.vue";
import CostList from "@page/cost/costList.vue";
import Card from "@page/user/card.vue";

const routerHistory = new createWebHistory();

const router = createRouter({
    history: routerHistory,
    routes: [{
        path: '/login',
        name: 'login',
        component: Login
    },{
        path: '/',
        name: 'gv',
        component: Gv
    },{
        path: '/user',
        name: 'user',
        component: User
    },{
        path: '/admin/gv',
        name: 'gv',
        component: Gv
    },{
        path: '/admin/village',
        name: 'village',
        component: Village
    },{
        path: '/admin/villageadmin',
        name: 'villageadmin',
        component: VillageAdmin
    },{
        path:'/device/guard',
        name:'guard',
        component:Guard
    },{
        path:'/user/group',
        name:'group',
        component:Group
    },{
        path:'/user/user',
        name:'user',
        component:User
    },{
        path:'/user/card',
        name:'card',
        component:Card
    },{
        path:'/user/building',
        name:'building',
        component:Building
    },{
        path:"/user/house",
        name:"house",
        component:House
    },{
        path:"/record/openRecord",
        name:"openRecord",
        component:OpenRecord
    },{
        path:"/cost/costList",
        name:"costList",
        component:CostList
    }]
})

export default router;