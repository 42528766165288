<template>
    <header class="header between">
        <section class="start">
            <span class="admin-title ai bold">易乾智慧人脸门禁系统</span>
        </section>
        <section class="center">
            <span class="iconfont i-user text-20 blue"></span>
            <span class="admin-name align blue">{{nickname}}</span>
            <span class="iconfont i-logout left text-22" @click="logout()"></span>
        </section>
    </header>
</template>
<script>
export default {
    props:{

    },
    data(){
        return{
            nickname:"管理员"
        }
    },
    mounted(){
        this.nickname = window.localStorage.getItem('nickName');
    },
    methods:{
        //退出登录
        logout(){
            this.$mybus.emit('logout');
        }
    }
}
</script>
<style>
    .header{
        padding:15px 20px;
        background-color:#fff;
        box-shadow:0 0 10px 5px rgba(0,0,0,.06);
    }
    .admin-title{
        font-size:30px;
        color:#4781ff;
    }
    .i-logout:hover{
        color:#4781ff;
        cursor:pointer;
    }
</style>