<template>
    <div class="time center" :class="[isTime ? 'timeActive' : '']">
        <div class="time-box">
            <header class="time-header">
                <p class="text-20 ai blue">时间选择</p>
            </header>
            <div class="time-content start wrap">
                <div class="time-item">
                    <y-select
                        :data="hourData"
                        defaultText="选择小时"
                        @getValue="getValue($event, 0)"
                    ></y-select>
                </div>
                <div class="time-item">
                    <y-select
                        :data="timeData"
                        defaultText="选择分钟"
                        @getValue="getValue($event, 1)"
                    ></y-select>
                </div>
                <div class="time-item">
                    <y-select
                        :data="timeData"
                        defaultText="选择秒"
                        @getValue="getValue($event, 2)"
                    ></y-select>
                </div>
            </div>
            <footer class="dialog-footer end">
                <div class="default right" @click="cancel">取消</div>
                <div class="button" @click="enter">确定</div>
            </footer>
        </div>
    </div>
</template>
<script>
import YSelect from "@components/YSelect.vue";
import Tool from "@util/tool.js";

export default {
    name: "time",
    props: {
        isTime: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        YSelect,
    },
    mounted() {
        let hours = [],
            times = [];
        for (let i = 0; i < 24; i++) {
            hours.push(Tool.styNum(i));
        }
        for (let j = 0; j < 60; j++) {
            times.push(Tool.styNum(j));
        }
        this.timeData = times;
        this.hourData = hours;
    },
    data() {
        return {
            timeData: [],
            hourData: [],
            hours: "00",
            mins: "00",
            second: "00",
        };
    },
    methods: {
        //选择时间
        getValue(e, level) {
            let { value} = e;
            switch (level) {
                case 0:
                    this.hours = value;
                    break;
                case 1:
                    this.mins = value;
                    break;
                case 2:
                    this.second = value;
                    break;
                default:
                    break;
            }
        },
        //取消
        cancel() {
            this.$emit("cancel");
        },
        //确定
        enter() {
            this.$emit("enter", {
                hours:this.hours,
                mins:this.mins,
                second:this.second
            });
        },
    },
};
</script>
<style scoped>
.time {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    transition: background-color 0.2s;
}
.timeActive {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.time-box {
    background-color: #ededed;
    border-radius: 4px;
}
.time-header {
    margin-bottom: 30px;
    padding: 20px 0 20px 15px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #fafafa;
}
.time-content {
    padding: 15px;
    width: 520px;
    height: 100px;
}
.time-item {
    margin-right: 12px;
}
.dialog-footer {
    margin-top: 10px;
    padding: 15px;
}
</style>