<template>
    <section class="search-container">
        <div class="button bottom" @click="dialogStatus(1)">新增管理员</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai">公司账号:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司账号"
                    v-model="searchAccount"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">电话号码:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入电话号码"
                    v-model="searchPhone"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">管理员名字:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入管理员名字"
                    v-model="searchName"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getManageList">查询</div>
        </div>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item width-2 ai text-15">名字</li>
                <li class="table-item ai text-15">电话</li>
                <li class="table-item width-2 ai text-15">身份证号码</li>
                <li class="table-item width-2 ai text-15">创建时间</li>
                <li class="table-item ai text-15">操作</li>
            </ul>
            <div class="table-container" v-if="villageManageList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in villageManageList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item width-2 center">
                            <span>{{ val.realName }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.phone }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.nUmber }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.cre_time }}</span>
                        </li>
                        <li class="table-item center">
                            <div class="button" @click="delAdmin(val)">删除</div>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar :page="page" :pageSize="pageSize" :total="total" @pageFunc="changePage"></page-bar>
    <y-dialog
        :isDialog="isDialog"
        title="新增小区管理员"
        @cancel="cencelDialog"
        @enter="enterDialog"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">姓名:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入姓名"
                        v-model="realName"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">电话:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入电话"
                        v-model="phone"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">身份证号码:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入身份证号码"
                        v-model="idNumber"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">密码:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入密码"
                        v-model="pwd"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from '@components/PageBar.vue';

export default {
    name: "admin",
    components: {
        YDialog,
        Toast,
        Loading,
        PageBar
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total:0,
            searchAccount: "",
            searchPhone: "",
            searchNick: "",
            searchName: "",
            searchAddress: "",
            isDatePicker: false,
            defaultSwitch: true,
            villageManageList: [],
            isDialog: false,
            phone: "",
            realName: "",
            pwd: "",
            surepwd: "",
            idNumber: "",
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
        };
    },
    mounted() {
        this.getManageList();
    },
    methods: {
        //获取管理员列表
        getManageList() {
            this.showLoading();
            let { page, pageSize, searchAccount, searchPhone, searchName } = this;
            post(Url.getManageList, {
                pagination: {
                    current: page,
                    pagesize: pageSize,
                    fist_time: "",
                    last_time: "",
                },
                account: searchAccount,
                polt_id: "",
                poltadmin_name: searchName,
                poltadmin_phone: searchPhone,
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].cre_time = Tool.styTime(list[i].cre_time);
                    }
                    this.villageManageList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast("管理员信息获取失败!");
                }
            });
        },
        //对话框显示隐藏
        dialogStatus(type) {
            this.isDialog = type === 1 ? true : false;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //确认对话框
        enterDialog() {
            this.showLoading();
            this.isDialog = false;
            let { phone, realName, pwd, idNumber } = this;
            post(Url.createVillageManage, {
                phone,
                realName,
                pwd,
                surepwd: pwd,
                idNumber,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getManageList();
                }
            });
        },
        //删除管理员
        delAdmin(val){
            this.showLoading();
            this.isDialog = false;
            post(Url.delVillageManage, {
                tenantID:val.tenantID
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getManageList();
                }
            });
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //改变页码
        changePage(page){
            this.page = page;
            this.getManageList();
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    padding:0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
</style>