//工具函数类
export default class Tool {
    constructor() {

    }
    //数字转换为星期
    static transWeek(week) {
        let num = parseInt(week),
            name = "";
        switch (num) {
            case 0:
                name = "天";
                break;
            case 1:
                name = "一";
                break;
            case 2:
                name = "二";
                break;
            case 3:
                name = "三";
                break;
            case 4:
                name = "四";
                break;
            case 5:
                name = "五";
                break;
            case 6:
                name = "六";
                break;
            default:
                break;
        }
        return '星期' + name;
    }
    //格式化后端返回时间 2021-07-28T10:56:57.1850422
    static styTime(date) {
        let dateArr = date.split("T"),
            timeArr = dateArr[1].split(":");
        return `${dateArr[0]} ${timeArr[0]}:${timeArr[1]}`
    }
    //检测参数是否为空
    static blockVerify(arr = []) {
        if (arr.length === 0) return { res: true };
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].val == "") {
                return {
                    res: false,
                    msg: `${arr[i].name}不能为空！`
                }
            }
        }
        return { res: true };
    }
    //将数组转化为字符串
    static arrTostr(arr, sign = ",") {
        if (arr.length === 0) return "";
        let str = ""
        for (let i = 0; i < arr.length; i++) {
            str += `${arr[i]}${sign}`
        }
        return str.slice(0, str.length - 1)
    }
    //格式化开门方式 1：二维码 2：IC卡 3：蓝牙 4：远程 5:人脸识别
    static openType(type) {
        let key = typeof type === 'number' ? type : parseInt(type),
            open = "";
        switch (key) {
            case 1:
                open = "二维码";
                break;
            case 2:
                open = "IC卡";
                break;
            case 3:
                open = "蓝牙";
                break;
            case 4:
                open = "远程";
                break;
            case 5:
                open = "人脸识别";
                break;
            default:
                open = "其他";
                break;
        }
        return open;
    }
    //格式化数字 大于9加0
    static styNum(num){
        num = typeof num === 'number' ? num : parseInt(num);
        return num > 9 ? num : `0${num}`;
    }
    //时间转时间戳
    static toTimestemp(date,time){
        if(date != "" && date.indexOf("-") === -1) return "";
        let text = "00:00:00";
        if(time.indexOf(":") !== -1) text = time;
        return (Date.parse(`${date} ${text}`) / 1000).toString();
    }
    //检测身份证格式
    static verifyIdCard(IdCard){
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        return reg.test(IdCard)
    }
    //压缩图片
    static compressImage(file) {
        let src,self = this;
        let files;
        const read = new FileReader()
        read.readAsDataURL(file)
        return new Promise(function (resolve, reject) {
            read.onload = function (e) {
                let img = new Image()
                img.src = e.target.result
                img.onload = function () {
                    let w = 300
                    let h = img.height / (img.width / 300)
                    // 生成canvas
                    let canvas = document.createElement('canvas')
                    let ctx = canvas.getContext('2d')
                    let base64
                    // 创建属性节点
                    canvas.setAttribute('width', w)
                    canvas.setAttribute('height', h)
                    ctx.drawImage(this, 0, 0, w, h)

                    base64 = canvas.toDataURL(file['type'], 1)
                    let fileBinary = self.dataURLtoFile(base64)
                    resolve(fileBinary)
                }
            }
        })
    };
    // 将base64转为二进制
    static dataURLtoBlob(dataurl) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    }
    // base64转码（将base64编码转回file文件）  此方法我没用到
    static dataURLtoFile(dataurl) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], { type: mime })
    }
}