<template>
    <ul class="column menu">
        <li class="menu-item column" v-for="(el, i) in menuList" :key="i">
            <div
                class="between menu-hover"
                @click="selectMenu(el, i)"
                :class="[menuIndex === i ? 'menu-bg' : '']"
            >
                <div class="start right">
                    <span
                        class="iconfont right text-20"
                        :class="el.icon"
                    ></span>
                    <span class="menu-name bold text-15 ai">{{ el.name }}</span>
                </div>
                <div
                    class="row-box"
                    :class="[menuIndex === i ? 'rowActive' : '']"
                    v-if="el.nextMenu.length > 0"
                >
                    <span class="iconfont i-row text-12"></span>
                </div>
            </div>
            <ul
                class="next-menu"
                :class="[menuIndex === i ? 'menuActive' : '']"
            >
                <li
                    class="next-item center"
                    :class="[
                        nextIndex === index && menuIndex === i
                            ? 'nextActive'
                            : '',
                    ]"
                    v-for="(val, index) in el.nextMenu"
                    :key="index"
                    @click.stop="selectNextMenu(val, index)"
                >
                    <span class="menu-name">{{ val.name }}</span>
                </li>
            </ul>
        </li>
    </ul>
</template>
<script>
import router from "@router/index.js";
export default {
    name: "menu-nav",
    data() {
        return {
            menuIndex: 0,
            nextIndex: null,
            menuList: [],
        };
    },
    mounted() {
        let role = window.localStorage.getItem('role'),
            roles = role.split(","),
            superAdmin = roles.indexOf("ServerAdmin") != -1 ? true : false,
            admin = roles.indexOf("Admin") !== -1 ? true : false,
            user = roles.indexOf("App_admin") !== -1 ? true : false;
        let adminMenu = this.meunByLimit(superAdmin, admin, user),
            userManage = {
                name: "人员管理",
                icon: "i-admin",
                url: "",
                nextMenu:adminMenu
            };
        this.menuList = [];
        let userList = [
            {
                name: "设备管理",
                icon: "i-iot",
                url: "",
                nextMenu: [
                    {
                        name: "门禁设备",
                        url: "/device/guard",
                    },
                ]
            },
            {
                name: "人员管理",
                icon: "i-user",
                url: "",
                nextMenu: [
                    {
                        name: "分组管理",
                        url: "/user/group",
                    },{
                        name:"楼栋管理",
                        url:"/user/building"
                    },{
                        name:"房间管理",
                        url:"/user/house"
                    },{
                        name: "人员管理",
                        url: "/user/user",
                    },{
                        name: "卡管理",
                        url: "/user/card",
                    }
                ]
            },
            {
                name: "物业费用",
                icon: "i-money-1",
                url: "",
                nextMenu: [
                    {
                        name: "物业费用分组",
                        url: "/cost/costList",
                    }
                ]
            },{
                name: "记录管理",
                icon: "i-record",
                url: "",
                nextMenu: [{
                    name:"开门记录",
                    url:"/record/openRecord"
                }],
            }
        ]
        if(!user) this.menuList.unshift(userManage)
        if(user){
            this.menuList = [...this.menuList,...userList]
        }
    },
    methods: {
        //一级菜单点击事件
        selectMenu(val, i) {
            let { menuIndex } = this;
            if (menuIndex == i) {
                this.menuIndex = null;
            } else {
                this.menuIndex = i;
            }
            this.nextIndex = null;
            if (val.url && val.url != "") router.push(val.url);
        },
        //二级菜单点击事件
        selectNextMenu(val, i) {
            this.nextIndex = i;
            if (val.url && val.url != "") router.push(val.url);
        },
        //根据权限展示菜单
        meunByLimit(superAdmin, admin, user) {
            if (superAdmin) {
                return [
                    {
                        name: "公司管理",
                        url: "/admin/gv",
                    },
                ];
            }
            if (admin) {
                return [
                    {
                        name: "小区管理",
                        url: "/admin/village",
                    },
                    {
                        name: "小区管理员",
                        url: "/admin/villageadmin",
                    },
                ];
            }
            if (user){
                return []
            }
        }
    },
};
</script>
<style>
.menu {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}
.menu-item {
    width: 175px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.menu-hover {
    padding: 15px 10px 15px 20px;
    background-color: #fff;
    font-size: 13px;
}
.menu-hover:hover {
    background-color: #4781ff;
    color: #fff;
    cursor: pointer;
}
.next-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s;
}
.menuActive {
    max-height: 210px;
}
.menu-bg {
    background-color: #4781ff;
    color: #fff;
}
.next-item {
    padding: 12px 0;
    font-size: 13px;
}
.next-item:hover {
    background-color: #b5ccfc;
    color: #fff;
    cursor: pointer;
}
.nextActive {
    background-color: #b5ccfc;
    color: #fff;
}
.row-box {
    transform: rotate(180deg);
    transition: transform 0.3s;
}
.rowActive {
    transform: rotate(0deg);
}
</style>