<template>
    <div class="login column-between">
        <div class="between login-content">
            <img src="@img/login.svg" alt="" class="login-img" />
            <div class="login-box column-center">
                <p class="ai blue login-title">易乾智慧人脸门禁系统</p>
                <div class="login-input start">
                    <span class="iconfont i-user blue text-20"></span>
                    <input
                        type="text"
                        class="input-item"
                        placeholder="请输入账号"
                        v-model="account"
                    />
                </div>
                <div class="login-input between">
                    <div class="start">
                        <span class="iconfont i-pwd blue text-20"></span>
                        <input
                            :type="[isEye ? 'text' : 'password']"
                            class="input-item"
                            placeholder="请输入密码"
                            v-model="password"
                        />
                    </div>
                    <span
                        class="iconfont blue text-20 pointer"
                        :class="[isEye ? 'i-eye' : 'i-eye-1']"
                        @click="changePwdType()"
                    ></span>
                </div>
                <div class="login-btn center" @click="login()">
                    <span class="text-18 white ai">登 录</span>
                </div>
            </div>
        </div>
        <footer class="center gray bottom">
            <span class="right">云南易乾科技版权所有</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">滇ICP备17004479号</a>
        </footer>
        <loading :isShow="isShowLoading" />
        <toast :isShow="isToast" :text="toastText" />
    </div>
</template>

<script>
import { post} from "@util/http.js";
import Url from "@util/url.js";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import Tool from "@util/tool.js";

export default {
    name: "login",
    components: {
        Toast,
        Loading,
    },
    data() {
        return {
            isAdmin: false,
            isEye: false,
            account: "",
            password: "",
            type: "",
            isShowLoading: false,
            isToast: false,
            toastText: "",
        };
    },
    created() {
        //判断是否为管理员登录
        let urlArr = window.location.href.split("/");
        let isSuperAdmin = urlArr.indexOf("superAdmin") !== -1 ? true : false,
            isAdmin = urlArr.indexOf("admin") !== -1 ? true : false;
        if (isSuperAdmin && !isAdmin) {
            this.type = "service_admin";
        } else if (!isSuperAdmin && isAdmin) {
            this.type = "";
        } else if (!isSuperAdmin && !isAdmin) {
            this.type = "App_admin";
        }
    },
    mounted(){
        document.addEventListener("keyup",(e)=>{
            if(e.key == "Enter") this.login();
        });
    },
    methods: {
        //改变密码显示类型
        changePwdType() {
            this.isEye = !this.isEye;
        },
        //登录
        login() {
            let { account, password, type } = this;
            if (!this.isFull(account, password)) return;
            this.showLoading();
            post(Url.login, {
                account,
                password,
                types: type,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.success) {
                    window.localStorage.setItem("nickName", res.data.nick);
                    window.localStorage.setItem("role", Tool.arrTostr(res.data.role));
                    window.localStorage.setItem("token", res.data.token);
                    window.localStorage.setItem("login",1);
                    window.localStorage.setItem("account",res.data.account);
                    setTimeout(() => {
                        this.$mybus.emit("login");
                    }, 1500);
                } else {
                    if (res.msg.indexOf("<a>") != -1) {
                        let paramArr = res.msg.split("<a>");
                        if (paramArr.length > 0) {
                            let param = paramArr[1].split("</a>");
                            setTimeout(() => {
                                window.location.href = `http://auth.ynyqkj.net/${param[0]}`;
                            }, 2500);
                        }
                    }
                }
            });
        },
        //检测是否输入完整
        isFull(account, password) {
            if (account == "") {
                this.showToast("账号不能为空!");
                return false;
            } else if (password == "") {
                this.showToast("密码不能为空!");
                return false;
            }
            return true;
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
    },
};
</script>
<style scoped>
.login {
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.login-content {
    height: calc(100vh - 35px);
}
.login-img {
    width: 55%;
}
.login-box {
    margin: 0 170px 0 0;
    padding: 45px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
}
.login-title {
    margin-bottom: 75px;
    font-size: 30px;
}
.login-input {
    margin-bottom: 40px;
    padding: 10px 5px;
    width: 80%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.input-item {
    margin-left: 10px;
}
.login-btn {
    margin: 100px auto 0;
    padding: 14px 0;
    width: 85%;
    border-radius: 26px;
    background-color: #4781ff;
    cursor: pointer;
}
.login-btn:hover {
    background-color: #6c97f5;
}
</style>