<template>
    <section class="search-container">
        <div class="button bottom" @click="addGroup">新增收费分组</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'searchVillage')"
                ></y-select>
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">分组名称:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入分组名称"
                    v-model="searchName"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getCostList">查询</div>
        </div>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item ai text-15">名称</li>
                <li class="table-item ai text-15">金额</li>
                <li class="table-item width-2 ai text-15">生效时间</li>
                <li class="table-item ai text-15">与面积关联</li>
                <li class="table-item ai text-15">收费周期</li>
                <li class="table-item ai text-15">宽限时间</li>
                <li class="table-item width-2 ai text-15">操作</li>
            </ul>
            <div class="table-container" v-if="costList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in costList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item center">
                            <span>{{ val.name }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.money / 100 + "元" }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.time }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.isAreaofpeg ? "是" : "否" }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.feeTime }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.limit }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <div class="button right" @click="setGroup(val)">编辑</div>
                            <div class="default" @click="delGroup(val)">删除</div>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar
        :page="page"
        :pageSize="pageSize"
        :total="total"
        @pageFunc="changePage"
    ></page-bar>
    <y-dialog
        :isDialog="isDialog"
        title="添加分组"
        @cancel="cencelDialog"
        @enter="createGroup"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">费用名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入费用名称"
                        v-model="feeName"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'village')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">与面积挂钩:</span>
                    <div class="left">
                        <y-switch :status="isLine" @change="getIsLineArea"></y-switch>
                    </div>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">间隔周期:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入间隔周期"
                        v-model="interval"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">间隔类型:</span>
                    <y-select
                        :data="['日','月','年']"
                        defaultText="选择间隔周期类型"
                        @getValue="getValue($event, 'interval')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">宽限周期:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入宽限周期"
                        v-model="grace"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">周期类型:</span>
                    <y-select
                        :data="['日','月','年']"
                        defaultText="选择宽限周期类型"
                        @getValue="getValue($event, 'grace')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">开始时间:</span>
                    <span class="left pointer" @click="showTimePicker">{{startTime}}</span>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">金额:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入金额(元)"
                        v-model="money"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">类型:</span>
                    <y-select
                        :data="['全体业主','部分业主']"
                        defaultText="选择业主类型"
                        @getValue="getValue($event, 'owner')"
                    ></y-select>
                </div>
                 <div class="add-item start top" v-if="ownerType === 1">
                    <span class="ai text-15 add-name">选择业主:</span>
                    <span class="left pointer" @click="showSelectRoom">{{selectData.length > 0 ? `已选择${selectData.length}户` : roomText}}</span>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">费用说明:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入费用说明"
                        v-model="feeTip"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isShowUser"
        title="房屋"
        @enter="hideUser"
        @cancel="hideUser"
    >
        <template v-slot:content>
            <div class="bind-admin wrap" v-if="isShowUser">
                <li
                    class="bind-item start"
                    v-for="(el, i) in roomList"
                    :key="i"
                >
                    <span class="iconfont text-20 pointer"
                        :class="[el.isSelect ? 'i-select' : 'i-no-select',el.isSelect ? 'blue' : '']"
                        @click="selectUser(el)"
                    ></span>
                    <span class="text-14 admin-name">{{ el.district }}{{el.buildingnum}}{{el.elementnumber}}{{el.doorno}}</span>
                </li>
            </div>
            <div class="start top">
                <span class="iconfont text-20 pointer"
                    :class="[allSelect ? 'i-select' : 'i-no-select',allSelect ? 'blue' : '']"
                    @click="allSelectUser"
                ></span>
                <span class="text-14 admin-name">全选本页</span>
                <span class="text-14 admin-name left ai" v-if="selectData.length > 0">已选{{selectData.length}}户</span>
            </div>
            <page-bar :page="roomPage" :pageSize="roomPageSize" :total="userTotal" @pageFunc="roomChangePage"></page-bar>
            <div class="no-box column center" v-if="roomList.length === 0">
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isSet"
        title="编辑"
        @cancel="cancelSet"
        @enter="enterSet"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">费用名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入费用名称"
                        v-model="setFeeName"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">与面积挂钩:</span>
                    <div class="left">
                        <y-switch :status="setIsLine" @change="getSetIsLineArea"></y-switch>
                    </div>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">间隔周期:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入间隔周期"
                        v-model="setInterval"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">间隔类型:</span>
                    <y-select
                        :data="['日','月','年']"
                        :defaultText="setIntervalType"
                        @getValue="getValue($event, 'setInterval')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">宽限周期:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入宽限周期"
                        v-model="setGrace"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">周期类型:</span>
                    <y-select
                        :data="['日','月','年']"
                        :defaultText="setGraceType"
                        @getValue="getValue($event, 'setGrace')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">开始时间:</span>
                    <span class="left pointer" @click="showTimePicker">{{setStartTime}}</span>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">金额:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入金额(元)"
                        v-model="setMoney"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">费用说明:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入费用说明"
                        v-model="setFeeTip"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <date-picker :isShow="isShowTimePicker" @cancel="cencelGetTime" @getValue="getStartTime"></date-picker>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import NoInfo from "@components/NoInfo.vue";
import YSwitch from "@components/YSwitch.vue";
import DatePicker from "@components/DatePicker.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from "@components/PageBar.vue";

export default {
    name: "costList",
    components: {
        YDialog,
        Toast,
        Loading,
        YSelect,
        NoInfo,
        PageBar,
        YSwitch,
        DatePicker
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total: 0,
            roomPage: 1,
            roomPageSize:50,
            userTotal: 0,
            searchVillage: "",
            searchName: "",
            villageData: [],
            villageList: [],
            villageId: "",
            isLine:0,
            selectData:[],
            costList: [],
            roomText:"点击选择业主",
            startTime:"点击选择时间",
            isShowTimePicker:false,
            isShowUser: false,
            roomList: [],
            isDialog: false,
            searchPhone: "",
            searchNick: "",
            allSelect: false,
            feeName:"",
            interval:null,
            intervalType:null,
            grace:null,
            graceType:null,
            money:null,
            ownerType:null,
            feeTip:"",
            isSet:false,
            costGroup:{},
            setFeeName:"",
            setIsLine:0,
            setInterval:0,
            setIntervalType:"",
            setGrace:0,
            setGraceType:"",
            setStartTime:"选择时间",
            setMoney:0,
            setFeeTip:"",
            isShowLoading: false,
            isToast: false,
            toastText: ""
        };
    },
    mounted() {
        this.getAdminVillage();
    },
    methods: {
        //获取分组列表
        getCostList() {
            this.showLoading();
            let { page, pageSize, searchVillage} = this;
            post(Url.getCostList, {
                pid:searchVillage,
                pagination: {
                    current:page,
                    pagesize:pageSize,
                    fist_time: "",
                    last_time: ""
                },
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.queryPropertyFee;
                    this.total = res.data.pagination.total;
                    for(let i = 0;i < list.length;i ++){
                        list[i].time = Tool.styTime(list[i].fretime);
                        list[i].feeTime = list[i].period + this.styDate(list[i].periodtype);
                        list[i].limit = list[i].defer + this.styDate(list[i].defertype);
                    }
                    this.costList = list;
                } else {
                    this.showToast("分组信息获取失败!");
                }
            });
        },
        //格式化后端年月日
        styDate(val){
            let time = typeof val === 'number' ? val : parseInt(val);
            if(time === 0) return "天";
            if(time === 1) return "月";
            if(time === 2) return "年";
        },
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name,
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //获取房屋
        getRoom(id){
            this.showLoading();
            let { roomPage,roomPageSize} = this;
            post(Url.getHouseList,{
                pagination: {
                    current:roomPage,
                    pagesize:roomPageSize,
                    fist_time: "",
                    last_time: ""
                },
                bid: "",
                pid:id,
                elementnumbe: "",
                doorno: ""
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    this.userTotal = res.data.pagination.total;
                    let list = res.data.list,
                        {selectData} = this;
                    for(let i = 0;i < list.length;i ++){
                        for(let j = 0;j < selectData.length;j ++){
                            if(selectData.indexOf(list[i].kid) !== -1){
                                list[i].isSelect = true;
                            }else{
                                list[i].isSelect = false;
                            }
                        }
                    }
                    this.roomList = list;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //展示房屋选择
        showSelectRoom(){
            this.isShowUser = true;
        },
        //人员信息分页
        roomChangePage(page) {
            this.allSelect = false;
            this.roomPage = page;
            this.getRoom(this.villageId);
        },
        //选择人员
        selectUser(val) {
            let { roomList } = this;
            for (let i = 0; i < roomList.length; i++) {
                if (roomList[i].kid === val.kid) {
                    this.roomList[i].isSelect = !roomList[i].isSelect;
                }
            }
            this.cacelSelect(1,val);
        },
        //全选
        allSelectUser() {
            let { roomList, allSelect } = this;
            for (let i = 0; i < roomList.length; i++) {
                roomList[i].isSelect = !allSelect;
            }
            let arr = allSelect ? [] : roomList;
            this.cacelSelect(2,arr);
            this.roomList = roomList;
            this.allSelect = !allSelect;
        },
        //统计所选数量
        cacelSelect(type,data){
            let {selectData} = this;
            if(type === 1){
                let i = selectData.indexOf(data.kid)
                if(i != -1){
                    selectData.splice(i,1)
                }else{
                    selectData.push(data.kid)
                }
            }
            this.selectData = selectData;
        },
        //隐藏分组人员
        hideUser() {
            this.isShowUser = false;
        },
        //对话框显示隐藏
        addGroup() {
            this.isDialog = true;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
            this.selectData = [];
        },
        //创建分组
        createGroup() {
            let { villageId,feeName,isLine,interval,intervalType,grace,money,graceType,startTime,feeTip,selectData,roomList} = this;
            this.showLoading();
            this.isDialog = false;
            post(Url.addCostGroup, {
                pid:villageId,
                name:feeName,
                isAreaofpeg:isLine === 0 ? false : true,
                period:parseInt(interval),
                periodtype:intervalType,
                defer:parseInt(grace),
                defertype:graceType,
                fretime:startTime + "T00:00:00",
                money:parseFloat(money) * 100,
                unit: 0,
                explain:feeTip,
                roomId:selectData
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("创建成功！");
                    this.getCostList();
                    this.selectData = [];
                    for(let i = 0;i < roomList.length;i ++){
                        roomList[i].isSelect = false;
                    }
                    this.roomList = roomList;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //删除分组
        delGroup(val) {
            this.showLoading();
            this.isDialog = false;
            post(Url.delCostGroup,{
                pid:val.polt_id,
                kid:val.kid
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getCostList();
                }
            });
        },
        //显示编辑内容
        setGroup(val){
            this.setFeeName = val.name;
            this.setIsLine = val.isAreaofpeg ? 1 : 0;
            this.setInterval = val.period;
            this.setIntervalType = this.styDate(val.periodtype);
            this.setGrace = val.defer;
            this.setGraceType = this.styDate(val.defertype);
            this.setStartTime = Tool.styTime(val.fretime).split(" ")[0];
            this.setMoney = parseInt(val.money) / 100;
            this.setFeeTip = val.explain;
            this.costGroup = val;

            this.isSet = true;
        },
        //取消编辑
        cancelSet(){
            this.isSet = false;
        },
        //确认编辑
        enterSet(){
            let {costGroup,setFeeName,setIsLine,setInterval,setIntervalType,setGrace,setGraceType,setStartTime,setMoney,setFeeTip} = this;
            this.showLoading();
            post(Url.setCostGroup,{
                pid:costGroup.polt_id,
                kid:costGroup.kid,
                name:setFeeName,
                isAreaofpeg:setIsLine === 1 ? true : false,
                period:setInterval,
                periodtype:this.initDate(setIntervalType),
                defer:setGrace,
                defertype:this.initDate(setGraceType),
                fretime:setStartTime + "T00:00:00",
                money:parseInt(setMoney) * 100,
                explain:setFeeTip
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getCostList();
                    this.isSet = false;
                }
            });
        },
        //格式化后端年月日
        initDate(val){
            if(typeof val === 'number') return val;
            if(val === "日") return 0;
            if(val === "月") return 1;
            if(val === "年") return 2;
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //获取是否关联面积
        getIsLineArea(e){
            this.isLine = e.status ? 1 : 0;
        },
        //获取设置是否关联面积
        getSetIsLineArea(e){
            this.setIsLine = e.status ? 1 : 0;
        },
        //显示时间选择
        showTimePicker(){
            this.isShowTimePicker = true;
        },
        //取消选择时间
        cencelGetTime(){
            this.isShowTimePicker = false;
        },
        //获取开始时间
        getStartTime(e){
            let time = `${e.year}-${e.month}-${e.day}`;
            this.isShowTimePicker = false;
            if(this.isSet){
                this.setStartTime = time;
                return;
            }
            this.startTime = time;
        },
        //获取所选内容
        getValue(e, key) {
            switch (key) {
                case "village":
                    let id = this.villageList[e.index].plot_id;
                    this.searchVillage = id;
                    this.villageId = id;
                    this.getRoom(id);
                    break;
                case "searchVillage":
                    this.searchVillage = this.villageList[e.index].plot_id;
                    break;
                case "interval":
                    this.intervalType = e.index;
                    break;
                case "grace":
                    this.graceType = e.index;
                    break;
                case "owner":
                    this.ownerType = e.index;
                    break;
                case "setInterval":
                    this.setIntervalType = e.index;
                    break;
                case "setGrace":
                    this.setGraceType = e.index;
                    break;
                default:
                    break;
            }
        },
        //改变页码
        changePage(page) {
            this.page = page;
            this.getCostList();
        },
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    margin: 15px;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.bind-admin {
    width: 1000px;
}
.bind-item {
    margin: 0 20px 15px 0;
}
.admin-name {
    margin-left: 8px;
}
.box-list {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    min-height: calc(100% - 550px);
    overflow-y: scroll;
    font-size: 0;
}
.box {
    display: inline-block;
    /* position: relative; */
    margin: 0 15px 15px 0;
}
.box-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
    font-size: 14px;
    transition: all 0.3s;
}
.box-container:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08),
        0 0 10px 5px rgba(0, 0, 0, 0.06) inset;
}
.box-header {
    padding: 10px;
    background-color: #f8f3f3;
    font-family: "ai";
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}
.box-content {
    padding: 15px 12px;
}
.box-item {
    margin-bottom: 15px;
}
.function-box {
    margin: 25px 0 15px;
}
.box-name {
    display: inline-block;
    width: 75px;
    font-size: 15px;
    font-weight: 600;
}
.box-text {
    display: inline-block;
    width: 250px;
}
.box-row {
    transform: rotate(180deg);
    transition: transform 0.2s;
    cursor: pointer;
}
.rowActive {
    transform: rotate(0);
}
.box-footer {
    position: relative;
}
.box-admin {
    position: absolute;
    top: 20px;
    left: -12px;
    padding: 10px;
    width: 329px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
}
.box-address {
    height: 35px;
}
</style>