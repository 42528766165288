<template>
    <section class="search-container">
        <div class="button bottom" @click="dialogStatus(1)">新增楼栋</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'village')"
                ></y-select>
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getBuildingList">查询</div>
        </div>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item ai text-15">小区</li>
                <li class="table-item ai text-15">区号</li>
                <li class="table-item width-2 ai text-15">楼栋号</li>
                <li class="table-item width-2 ai text-15">楼层</li>
                <li class="table-item width-2 ai text-15">操作</li>
            </ul>
            <div class="table-container" v-if="buildingList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in buildingList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item center">
                            <span>{{ val.polt_name }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.district }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.buildingnum }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.floors }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <div class="default" @click="delBuilding(val)">删除</div>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar :page="page" :pageSize="pageSize" :total="total" @pageFunc="changePage"></page-bar>
    <y-dialog
        :isDialog="isDialog"
        title="新增楼栋"
        @cancel="cencelDialog"
        @enter="enterDialog"
    >
        <template v-slot:content>
            <div class="add-box">
                 <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'selectVillage')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">片区名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="片区名称"
                        v-model="address"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">楼栋号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入楼栋号(如:3栋)"
                        v-model="no"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">层数:</span>
                    <input
                        type="number"
                        class="add-input"
                        placeholder="请输入层数"
                        v-model="floor"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import PageBar from '@components/PageBar.vue';

export default {
    name: "building",
    components: {
        YDialog,
        Toast,
        YSelect,
        Loading,
        PageBar
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total:0,
            searchVillage:"",
            searchNick: "",
            searchAddress: "",
            isDatePicker: false,
            defaultSwitch: true,
            buildingList:[],
            villageList:[],
            villageData:[],
            villageManageList: [],
            village:"",
            address:"",
            no:"",
            floor:null,
            isDialog: false,
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
        };
    },
    mounted() {
        this.getAdminVillage();
    },
    methods: {
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //获取楼栋列表
        getBuildingList() {
            this.showLoading();
            let { page, pageSize,searchVillage} = this;
            post(Url.getBuildingList,{
                current:page,
                pagesize:pageSize,
                fist_time:"",
                last_time:"",
                pid:searchVillage,
                district:"",
                buildingnum:"",
                floors: 0
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    this.buildingList = res.data.list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast("楼栋信息获取失败!");
                }
            });
        },
        //对话框显示隐藏
        dialogStatus(type) {
            this.isDialog = type === 1 ? true : false;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //确认添加楼栋
        enterDialog() {
            this.showLoading();
            this.isDialog = false;
            let {village,address,no,floor} = this;
            post(Url.addBuilding,{
                id:"",
                pid:village,
                district:address,
                buildingnum:no,
                floors:parseInt(floor)
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getBuildingList();
                }
            });
        },
        //删除楼栋
        delBuilding(val){
            this.showLoading();
            this.isDialog = false;
            post(Url.delBuilding,{
                pid:val.polt_id,
                list: [{
                    key:val.id
                }]
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getBuildingList();
                }
            });
        },
        //选择小区
        getValue(val,key){
            switch(key){
                case 'village':
                    this.searchVillage = this.villageList[val.index].plot_id;
                    break;
                case 'selectVillage':
                    this.village = this.villageList[val.index].plot_id;
                    break;
                default:
                    break;
            }
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //改变页码
        changePage(page){
            this.page = page;
            this.getBuildingList();
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    padding:0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
</style>