<template>
    <div class="page-box center">
        <ul class="page-bar center blue" v-if="Math.ceil(total / pageSize) > 1">
            <li class="page-item ai pointer" @click="pageFunc('first')">
                第一页
            </li>
            <li class="page-item ai pointer" @click="pageFunc('left')">
                上一页
            </li>
            <li class="page-item ai">{{ page }}</li>
            <li class="page-item ai pointer" @click="pageFunc('next')">
                下一页
            </li>
            <li class="page-item ai pointer" @click="pageFunc('last')">
                最后一页
            </li>
            <li class="page-item ai">共{{ Math.ceil(total / pageSize) }}页</li>
            <li class="page-item ai">共{{total}}条</li>
        </ul>
        <li class="page-item ai blue" v-if="Math.ceil(total / pageSize) <= 1 && total > 0">共{{total}}条数据</li>
    </div>
</template>
<script>
export default {
    name:"page-bar",
    props:{
        page:{
            default:1,
            type:Number
        },
        pageSize:{
            default:10,
            type:Number
        },
        total:{
            default:0,
            type:Number
        }
    },
    data(){
        return{

        }
    },
    methods:{
        //分页操作
        pageFunc(type) {
            let {page,pageSize,total} = this,
                resPage = page;
            switch (type) {
                case "first":
                    if(page === 1) return;
                    resPage = 1;
                    break;
                case "left":
                    if(page === 1) return;
                    resPage = page - 1;
                    break;
                case "next":
                    if(page === Math.ceil(total / pageSize)) return;
                    resPage = page + 1;
                    break;
                case "last":
                    if(page === Math.ceil(total / pageSize)) return;
                    resPage = Math.ceil(total / pageSize);
                    break;
                default:
                    break;
            }
            if(page !== resPage) this.$emit('pageFunc',resPage);
        }
    }
}
</script>
<style scoped>
    .page-item{
        padding:15px 10px;
        font-size:15px;
    }
</style>