//接口
const Url = {
    login:"Admin/Login",//登录
    getCompanyList:"Company/GetCompanyList",//获取公司列表
    createCompany:"Company/SaveCompany",//创建公司
    getVerifyCode:"UserControl/GetVerifyImage",//获取图形验证码
    getVillageList:"Company/GetPlotList",//获取小区列表
    createVillage:"Company/SavePlot",//创建小区
    delVillage:"Company/DropPlot",//删除小区
    getManageList:"Company/GetCompayAdminList",//公司获取管理员列表
    createVillageManage:"Company/SavePlotAdmin",//创建小区管理员
    delVillageManage:"Company/DropPolotAdmin",//删除小区管理员
    bindAdmin:"Company/BindPolotAdmin",//绑定小区管理员
    getVillageAdmin:"Company/GetPlotAdminList",//获取小区名下管理员
    getAdminVillage:"Company/GetAdminPolts",//获取管理员所属小区
    getDeviceList:"DeviceManage/GetDeviceLists",//获取门禁列表
    addDevice:"DeviceManage/SaveDevice",//创建门禁列表
    createGroup:"Group/SaveGroup",//创建分组
    delGroup:"Group/DeleteGroup",//删除分组
    getGroup:"Group/GetGroupList",//获取分组
    getGroupDevice:"Group/GetGroupDevice",//获取当前分组门禁列表
    getGroupUser:"Group/GetGroupStaff",//获取分组人员列表
    delGroupUser:"Group/DeleteGroupStaff",//删除分组人员
    bindDevice:"Group/SaveGroupDevice",//分组绑定设备
    delGroupDevice:"Group/DeleteGroupDevice",//移除分组门禁设备
    openDoor:"DeviceManage/RemoteOpenDoor",//开门
    restartDevice:"DeviceManage/RestartDevice",//重启设备
    resetDevice:"DeviceManage/ResetDevice",//重置设备
    delDevice:"DeviceManage/DeleteDevice",//删除门禁
    initDevice:"ZYDevice/setPassWord",//初始化设备
    getUserList:"Staff/GetStaffList",//获取人员列表
    verifyUser:"Staff/DeleteStaff",//人员审核
    addCard:"Staff/AddIC",//添加卡
    getCardList:"Staff/GetICCardList",//获取卡列表
    isUseFace:"Staff/UsingOrDisableStaff",//是否启用人脸
    setUserGroup:"Group/SaveGroupStaff",//设置人员分组
    getBuildingList:"BuildingRecord/GetBuildingRecordList",//获取楼栋列表
    addBuilding:"BuildingRecord/SaveBuildingRecord",//新增楼栋
    delBuilding:"BuildingRecord/DeleteBuildingRecord",//删除楼栋
    getHouseList:"BuildingRecord/GetRoomsRecord",//获取房间列表
    createHouse:"BuildingRecord/SaveRoomsRecord",//创建房间
    delHouse:"BuildingRecord/DropRoomsRecord",//删除房间
    getRecordList:"OpenRecord/GetUserOpenList",//获取门禁记录
    getCostList:"PropertyFee/queryPropertyFee",//获取物业费列表
    addCostGroup:"PropertyFee/AddGroupPropertyFee",//新增物业费用分组
    delCostGroup:"PropertyFee/DropPropertyFee",//删除物业分组
    setCostGroup:"PropertyFee/UpdatePropertyFee",//修改物业分组
    getAliOSS:"unionpay/GetAliyunSTS",//获取阿里云oss参数
    addUser:"Staff/AddStaff",//新增人员
    editUser:"Staff/SaveStaff",//编辑人员
}

export default Url;
