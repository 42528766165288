<template>
    <section class="search-container">
        <div class="button bottom" @click="addCardStatus(1)">
            <span class="left">新增卡</span>
        </div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'village')"
                ></y-select>
            </div>
            <div class="bottom center">
                <div class="button right" @click="getCardList">查询</div>
                <div class="default left" @click="getCardList">
                    <span class="iconfont i-reset"></span>
                    <span class="left">刷新</span>
                </div>
            </div>
        </section>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item ai text-15">卡号</li>
                <li class="table-item ai width-2 text-15">小区</li>
                <li class="table-item ai width-2 text-15">创建时间</li>
                <li class="table-item ai width-3 text-15">操作</li>
            </ul>
            <div class="table-container" v-if="cardList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in cardList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item center">
                            <span>{{ val.card_no }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.polt_name }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.cre_time }}</span>
                        </li>
                        <li class="table-item width-3 center">
                            <div class="default" @click="verifyCard(val, -3)">删除</div>
                            <div class="button left" @click="groupStatus(val)">分配分组</div>
                            <!-- <div class="button left" @click="showEditCard(val)">编辑</div> -->
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar
        :page="page"
        :pageSize="pageSize"
        :total="total"
        @pageFunc="changePage"
    ></page-bar>
    <y-dialog
        :isDialog="isAddCard"
        title="新增卡"
        @cancel="addCardStatus(2)"
        @enter="addCard"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'addCard')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">卡号:</span>
                    <input
                        type="text"
                        class="add-input"
                        @keydown="cardInput($event)"
                        placeholder="请输入卡号"
                        v-model="cardNum"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isShowGroup"
        title="卡分组"
        @cancel="hideGroup"
        @enter="cardGroup"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属分组:</span>
                    <y-select
                        :data="groupData"
                        defaultText="选择分组"
                        @getValue="getValue($event, 'group')"
                    ></y-select>
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isEditCard"
        title="编辑卡"
        @cancel="hideEditCard"
        @enter="editCard"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">卡号:</span>
                    <input
                        type="text"
                        class="add-input"
                        :placeholder="card.card_no"
                        v-model="editCardNum"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import { post, get, fullPost } from "@util/http.js";
import Tool from "@util/tool.js";
import Url from "@util/url.js";
import PageBar from "@components/PageBar.vue";
import YSwitch from "@components/YSwitch.vue";

export default {
    name: "card",
    components: {
        YDialog,
        Toast,
        YSelect,
        Loading,
        PageBar,
        YSwitch,
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total: 0,
            searchVillage: "",
            user: {},
            cardList: [],
            villageList: [],
            villageData: [],
            village: "",
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddCard: false,
            addCardVillageId: "",
            cardNum: "",
            isShowGroup:false,
            card:{},
            groupList:[],
            groupData:[],
            groupVillageId:"",
            groupId:"",
            isEditCard:false
        };
    },
    mounted() {
        this.getAdminVillage();
    },
    methods: {
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name,
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //获取卡列表
        getCardList() {
            this.showLoading();
            let {
                page,
                pageSize,
                searchVillage
            } = this;
            post(Url.getCardList, {
                pagination: {
                    total: 0,
                    pagesize: pageSize,
                    current: page,
                    fist_time: "",
                    last_time: "",
                },
                pid: searchVillage
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    this.cardList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        // 相关操作
        verifyCard(val, status) {
            this.showLoading();
            post(Url.verifyUser, {
                pid: val.polt_id,
                rid: val.rid,
                state: status,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("操作成功!");
                    this.getCardList();
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //新增卡
        addCard() {
            let { addCardVillageId, cardNum } = this;
            console.log(addCardVillageId, cardNum);
            post(Url.addCard, {
                pid: addCardVillageId,
                card_no: cardNum,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getCardList();
                    this.isAddCard = false;
                }
            });
        },
        //对话框显示隐藏
        dialogStatus(val) {
            this.isDialog = true;
            this.user = val;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //获取小区分组
        getGroup(id){
            post(Url.getGroup, {
                pagination: {
                    total: 15,
                    pagesize: 500,
                    current: 1,
                    fist_time: "",
                    last_time: "",
                },
                group_name: "",
                pid: id,
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list,
                        groupData = [];
                    for (let i = 0; i < list.length; i++) {
                        groupData.push(list[i].group_name);
                    }
                    this.groupList = list;
                    this.groupData = groupData;
                } else {
                    this.showToast("分组信息获取失败!");
                }
            });
        },
        //获取选择结果
        getValue(val, key) {
            let i = val.index,
                {villageList,groupList} = this;
            switch (key) {
                case "village": //查询卡选择小区
                    let id = villageList[i].plot_id;
                    this.searchVillage = id;
                    this.getGroup(id);
                    break;
                case "addCard": //新增卡选择小区
                    this.addCardVillageId = villageList[i].plot_id;
                    break;
                case "group": //选择分组
                    this.groupId = groupList[i].rid;
                    break;
                default:
                    break;
            }
        },
        //阻止刷卡后自动跳转
        cardInput(e){
            // e.preventDefault();
            // e.stopPropagation();
        },
        //显示分组
        groupStatus(val){
            this.card = val;
            this.isShowGroup = true;
        },
        // 隐藏卡分组
        hideGroup(){
            this.isShowGroup = false;
        },
        //卡分组
        cardGroup(){
            this.showLoading();
            this.isDialog = false;
            let { groupId, card } = this;
            post(Url.setUserGroup, {
                pid: card.polt_id,
                group_id: groupId,
                staff_ids: [card.rid],
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if(res.status){
                    this.isShowGroup = false;
                }
            });
        },
        //显示编辑卡
        showEditCard(val){
            this.card = val;
            this.isEditCard = true;
        },
        //隐藏编辑卡
        hideEditCard(){
            this.isEditCard = false;
        },
        //编辑卡
        editCard(){
            let {editCardNum,card} = this;
            post(Url.addCard, {
                pid: card.polt_id,
                card_no: editCardNum,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getCardList();
                    this.isEditCard = false;
                }
            });
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //改变页码
        changePage(page) {
            this.page = page;
            this.getCardList();
        },
        addCardStatus(status) {
            this.isAddCard = status === 1 ? true : false;
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.user-img {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}
.big-user-img {
    height: 450px;
}
</style>