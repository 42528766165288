<template>
    <div class="date-picker center" v-show="isShow">
        <div class="date-container column-center">
            <header class="date-header ai between">
                <span
                    class="iconfont i-left text-22 pointer"
                    :class="{ errCursor: isStart }"
                    @click="changeMonth(1)"
                ></span>
                <div class="center date-y">
                    <span class="right text-30 pointer" @click="changeYearShow">{{ y }}年</span>
                    <span class="left text-20">{{ m }}月</span>
                    <div class="year-list" :class="{ yearActive : isShowYear}">
                        <li
                            class="year-item"
                            v-for="(el, i) in yearList"
                            :key="i"
                            @click="selectYear(el)"
                        >
                            {{ el }}
                        </li>
                    </div>
                </div>
                <span
                    class="iconfont i-left left-trans text-22 pointer"
                    :class="{ errCursor: isEnd }"
                    @click="changeMonth(2)"
                ></span>
            </header>
            <ul class="week-list list-box">
                <li
                    class="date-item week-item"
                    v-for="(val, i) in week"
                    :key="i"
                >
                    {{ val }}
                </li>
            </ul>
            <ul class="day-list list-box">
                <li
                    class="date-item day-item"
                    :class="[d === el.day ? 'dayActive' : '',el.isChoose ? '' : 'noneDay']"
                    v-for="(el, index) in days"
                    :key="index"
                    @click="chooseDay(el)"
                >
                    {{ el.day }}
                </li>
            </ul>
            <footer class="end date-footer">
                <span
                    class="iconfont i-close-full footer-icon"
                    @click="dayHandle(1)"
                ></span>
                <span
                    class="iconfont i-success footer-icon"
                    @click="dayHandle(2)"
                ></span>
            </footer>
        </div>
    </div>
</template>
<script>
import Tool from "@util/tool.js";
export default {
    props: {
        isShow: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            yearList: [],
            isShowYear: false,
            week: ["日", "一", "二", "三", "四", "五", "六"],
            days: [],
            y: null,
            m: null,
            d: null,
            isStart: false,
            isEnd: false,
        };
    },
    create() {},
    mounted() {
        let now = new Date(),
            y = now.getFullYear(),
            m = now.getMonth() + 1,
            d = now.getDate();
        this.y = y;
        this.m = m;
        this.d = d;
        this.createDays(y, m, 1);
        //组装年份数组
        this.createYear();
    },
    methods: {
        //组装年份数组
        createYear() {
            let yearArr = [],
                number = 2050 - 1970;
            for (let i = 0; i < number + 1; i++) {
                yearArr.push(1970 + i);
            }
            this.yearList = yearArr;
        },
        //改变月份选择列表状态
        changeYearShow(){
            this.isShowYear = !this.isShowYear;
        },
        //选择年份
        selectYear(y){
            this.y = y;
            this.createDays(y,this.m, 1);
            this.isShowYear = false;
        },
        //根据日期获取星期几
        getWeek(date) {
            let time = new Date(date);
            return time.getDay();
        },
        //根据月份获取当月天数
        getMonthDays(year, month) {
            let num = parseInt(month),
                day_31 = [1, 3, 5, 7, 8, 10, 12],
                day_30 = [4, 6, 9, 11];
            if (num === 2) {
                return this.isLeapYear(year) ? 29 : 28;
            } else if (day_31.indexOf(num) != -1) {
                return 31;
            } else if (day_30.indexOf(num) != -1) {
                return 30;
            } else {
                console.log(`输入值${num}不在合法月份数值内！`);
            }
        },
        //判断平年闰年
        isLeapYear(year) {
            return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
        },
        //获取上个月月份
        getLastMonth(month) {
            if (month === 1) {
                return 12;
            } else {
                return month - 1;
            }
        },
        //组装日期数组
        createDays(y, m, d) {
            let dayArr = [],
                days = this.getMonthDays(y, m),
                lastDays = this.getMonthDays(y, this.getLastMonth(m)),
                monDay = this.getWeek(`${y}-${m}-${d}`);
            for (let i = 0; i < monDay; i++) {
                let day = lastDays - (monDay - i) + 1;
                dayArr.push({
                    day,
                    isChoose: false,
                });
            }
            for (let j = 0; j < days; j++) {
                let day = j + 1;
                dayArr.push({
                    day,
                    isChoose: true,
                });
            }
            this.days = dayArr;
        },
        //选择日期
        chooseDay(day) {
            if (!day.isChoose) return;
            this.d = day.day;
        },
        //改变月份
        changeMonth(type) {
            this.d = null;
            let { y, m } = this;
            if (type === 1) {
                if (m === 1) {
                    m = 12;
                    y--;
                } else {
                    m--;
                }
            } else {
                if (m === 12) {
                    m = 1;
                    y++;
                } else {
                    m++;
                }
            }
            this.y = y;
            this.m = m;
            this.createDays(y, m, 1);
        },
        //取消 确认日期
        dayHandle(type) {
            let { y, m, d } = this,
                week = this.getWeek(`${y}-${m}-${d}`);
            if (type === 1) {
                this.$emit("cancel");
            } else {
                this.$emit("getValue", {
                    year: y,
                    month: m,
                    day: d,
                    week,
                    weekName: Tool.transWeek(week),
                });
            }
        },
    },
};
</script>
<style scoped>
.date-picker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index:2;
}
.date-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
    overflow: hidden;
}
.date-header {
    margin-bottom: 5px;
    padding: 20px 15px;
    width: 350px;
    background-color: #6c97f5;
    color: #fff;
}
.date-y {
    position: relative;
}
.year-list {
    position: absolute;
    top: 35px;
    left: 0px;
    width: 107px;
    max-height:0;
    background-color: #fff;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
    border-radius:3px;
    color: #666;
    text-align:center;
    font-family:'micro';
    overflow-y: scroll;
    transition: max-height 0.3s;
}
.yearActive{
    max-height:270px;
}
.year-item{
    padding:8px 0;
}
.year-item:hover{
    background-color:#ededed;
    cursor: pointer;
}
.left-trans {
    transform: rotate(180deg);
}
.list-box {
    width: 328px;
    font-size: 0;
}
.week-list {
    margin-bottom: 6px;
}
.date-item {
    display: inline-block;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    text-align: center;
}
.date-item:nth-child(7n) {
    margin-right: 0;
}
.week-item {
    font-size: 15px;
    font-weight: 600;
}
.day-item {
    margin-bottom: 8px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
}
.day-item:hover {
    background-color: #ededed;
    cursor: pointer;
}
.dayActive {
    background-color: #6c97f5 !important;
    color: #fff;
    font-weight: 900;
}
.noneDay {
    color: #999;
    background-color: #fff !important;
}
.noneDay:hover {
    background-color: #fff;
    cursor: default;
}
.date-footer {
    margin-right: 45px;
    padding: 0 15px 15px;
    width: 100%;
}
.footer-icon {
    font-size: 45px;
    line-height: 50px;
}
.footer-icon:hover {
    font-size: 50px;
    color: #6c97f5;
    cursor: pointer;
}
.i-success {
    margin-left: 10px;
}
</style>