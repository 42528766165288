<template>
    <section class="search-container">
        <section class="search-box top bottom start wrap">
            <div class="search-rule start">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'village')"
                ></y-select>
            </div>
            <div class="search-rule start">
                <span class="text-15 ai">设备SN:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入设备SN"
                    v-model="searchSn"
                />
            </div>
            <div class="search-rule start">
                <span class="text-15 ai">名字:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入名字"
                    v-model="searchName"
                />
            </div>
            <div class="search-rule start">
                <span class="text-15 ai">电话:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入电话"
                    v-model="searchPhone"
                />
            </div>
            <div class="search-rule start">
                <span class="text-15 ai right">开门方式:</span>
                <y-select
                    :data="['全部','二维码','IC卡','蓝牙','远程','人脸识别']"
                    defaultText="全部"
                    @getValue="getValue($event, 'open')"
                ></y-select>
            </div>
            <div class="search-rule start">
                <span class="text-15 ai right">开始时间:</span>
                <div class="default right pointer" @click="getTimeRes(1,1)">{{startDate}}</div>
                <div class="default pointer" @click="getTimeRes(2,1)">{{startTime}}</div>
            </div>
            <div class="search-rule start">
                <span class="text-15 ai right">结束时间:</span>
                <div class="default right pointer" @click="getTimeRes(1,2)">{{endDate}}</div>
                <div class="default pointer" @click="getTimeRes(2,2)">{{endTime}}</div>
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getRecordList">查询</div>
        </div>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item ai text-15">小区</li>
                <li class="table-item width-2 ai text-15">设备名称</li>
                <li class="table-item ai text-15">设备SN</li>
                <li class="table-item ai text-15">开门对象</li>
                <li class="table-item ai text-15">手机号</li>
                <li class="table-item ai text-15">开门方式</li>
                <li class="table-item width-2 ai text-15">时间</li>
            </ul>
            <div class="table-container" v-if="recordList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in recordList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item center">
                            <span>{{ val.nick }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.name }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.sn }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.realname }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.mobile }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.openType }}</span>
                        </li>
                        <li class="table-item width-2 center">
                            <span>{{ val.openTime }}</span>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar :page="page" :pageSize="pageSize" :total="total" @pageFunc="changePage"></page-bar>
    <date-picker :isShow="isDatePicker" @cancel="hideDate" @getValue="getDate"></date-picker>
    <time-picker :isTime="isTimePicker" @cancel="hideTime" @enter="getTimeVal"></time-picker>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from '@components/PageBar.vue';
import DatePicker from '@components/DatePicker.vue';
import TimePicker from "@components/TimePicker.vue";

export default {
    name: "openRecord",
    components: {
        Toast,
        YSelect,
        Loading,
        PageBar,
        DatePicker,
        TimePicker
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total:0,
            searchVillage:"",
            searchPhone: "",
            searchName: "",
            searchSn:"",
            openType:0,
            isDatePicker:false,
            isTimePicker:false,
            isStartDate:true,
            isStartTime:true,
            startDate:"选择日期",
            endDate:"选择日期",
            startTime:"选择时间",
            endTime:"选择时间",
            recordList:[],
            buildingList:[],
            buildingData:[],
            villageList:[],
            villageData:[],
            villageManageList: [],
            village:"",
            building:"",
            unitName:"",
            familyNum:"",
            area:null,
            isDialog: false,
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
        };
    },
    mounted() {
        this.getAdminVillage();
    },
    methods: {
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //获取门禁记录
        getRecordList() {
            this.showLoading();
            let { page, pageSize,searchVillage,searchName,searchPhone,searchSn,openType,startDate,endDate,startTime,endTime} = this;
            post(Url.getRecordList,{
                pid:searchVillage,
                sn:searchSn,
                phone:searchPhone,
                name:searchName,
                type:openType,
                pagination: {
                    current:page,
                    pagesize:pageSize,
                    fist_time:Tool.toTimestemp(startDate,startTime),
                    last_time:Tool.toTimestemp(endDate,endTime)
                }
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for(let i = 0;i < list.length;i ++){
                        let time = list[i].time.split("T");
                        list[i].openTime = `${time[0]} ${time[1]}`
                        list[i].openType = Tool.openType(list[i].type)
                    }
                    this.recordList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //显示日期选择
        getTimeRes(type,state){
            if(type === 1){
                this.isDatePicker = true;
                this.isStartDate = state === 1 ? true : false;
            }else{
                this.isTimePicker = true;
                this.isStartTime = state === 1 ? true : false;
            }
        },
        //获取日期
        getDate(date){
            let text = `${date.year}-${Tool.styNum(date.month)}-${Tool.styNum(date.day)}`;
            if(this.isStartDate){
                this.startDate = text;
            }else{
                this.endDate = text;
            }
            this.isDatePicker = false;
        },
        //获取时间
        getTimeVal(time){
            let text = `${time.hours}:${time.mins}:${time.second}`;
            if(this.isStartTime){
                this.startTime= text;
            }else{
                this.endTime = text;
            }
            this.isTimePicker = false;
        },
        //隐藏日期选择框
        hideDate(){
            this.isDatePicker = false;
        },
        //隐藏时间选择框
        hideTime(){
            this.isTimePicker = false;
        },
        //对话框显示隐藏
        dialogStatus(type) {
            this.isDialog = type === 1 ? true : false;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //选择小区
        getValue(val,key){
            switch(key){
                case 'village':
                    this.searchVillage = this.villageList[val.index].plot_id;
                    break;
                case 'open':
                    this.openType = val.index;
                    break;
                default:
                    break;
            }
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //改变页码
        changePage(page){
            this.page = page;
            this.getRecordList();
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    padding:0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
</style>