<template>
    <div class="page">
        <login v-if="isLogin" />
        <div class="column" v-else>
            <Header />
            <div class="start all">
                <menu-nav />
                <div class="content">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@components/Header.vue";
import MenuNav from "@components/MenuNav.vue";
import Login from "@page/user/login.vue";
import router from "@router/index.js";

export default {
    name: "home",
    components: {
        Header,
        MenuNav,
        Login,
    },
    data() {
        return {
            isLogin: true,
        };
    },
    mounted() {
        let login = window.localStorage.getItem('login');
        this.isLogin = login == 1 ? false : true;
        //监听登录成功事件
        this.$mybus.on("login", () => {
            this.isLogin = false;
            let role = window.localStorage.getItem('role'),
                roles = role.split(","),
                superAdmin = roles.indexOf("ServerAdmin") != -1 ? true : false,
                admin = roles.indexOf("Admin") !== -1 ? true : false,
                user = roles.indexOf("App_admin") !== -1 ? true : false;
            let url = this.linkByLimit(superAdmin, admin, user);
            router.push(url);
        });
        //监听退出登录事件
        this.$mybus.on("logout", () => {
            this.isLogin = true;
            window.localStorage.setItem("login",0);
            router.push("/");
        });
    },
    methods: {
        toLogin() {
            this.isLogin = true;
        },
        //根据权限跳转
        linkByLimit(superAdmin, admin, user){
            if(superAdmin) return "/admin/gv";
            if(admin) return "/admin/village";
            if(user) return "/device/guard";
            return "/login"
        }
    },
};
</script>
<style>
.page {
    width: 100vw;
    height: 100vh;
}
.all {
    height: calc(100vh - 64px);
    overflow: hidden;
}
.content {
    height: 100%;
    width: 100%;
    overflow:scroll;
}
</style>