<template>
    <section class="search-container">
        <div class="button bottom" @click="dialogStatus(1)">新增公司</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai">公司名称:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司名称"
                    v-model="searchCompany"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">公司账号:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司账号"
                    v-model="searchAccount"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">电话号码:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司电话号码"
                    v-model="searchPhone"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">联系人:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司联系人"
                    v-model="searchNick"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">地址:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入公司地址"
                    v-model="searchArea"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getCompanyList">查询</div>
        </div>
    </section>
    <div class="table-box">
        <Table :columns="columns" :data="companyList" />
    </div>
    <y-address :isAddress="isAddress" @cancel="hideAddress" @enter="getAddress"></y-address>
    <y-dialog
        :isDialog="isDialog"
        title="新增公司"
        @cancel="cencelDialog"
        @enter="enterDialog"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">公司账号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入账号"
                        v-model="account"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">公司名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入公司名称"
                        v-model="company"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">公司负责人:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入公司负责人"
                        v-model="manager"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">手机号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入手机号"
                        v-model="phone"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">邮箱:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入邮箱"
                        v-model="email"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">地区:</span>
                    <span class="text-15 pointer" @click="showAddress">{{address}}</span>
                </div>
                <div class="add-item start top bottom">
                    <span class="ai text-15 add-name">登录密码:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入登录密码"
                        v-model="password"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">图形验证码:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入验证码"
                        v-model="code"
                    />
                    <div class="code-box" v-if="codeImg != ''">
                        <img class="code-img" :src="codeImg" />
                        <div class="code-tip" @click="getVerifyCode">
                            换一张
                        </div>
                    </div>
                    <div class="button left" @click="getVerifyCode" v-else>
                        获取验证码
                    </div>
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Table from "@components/Table.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YAddress from "@components/Address.vue";
import { post, fullGet } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";

export default {
    name: "gv",
    components: {
        YDialog,
        Table,
        Toast,
        Loading,
        YAddress
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            searchCompany: "",
            searchAccount: "",
            searchPhone: "",
            searchNick: "",
            searchArea: "",
            isDatePicker: false,
            defaultSwitch: true,
            columns: [
                {
                    name: "账号",
                    key: "account",
                },
                {
                    name: "公司名称",
                    key: "name",
                },
                {
                    name: "负责人",
                    key: "nick",
                    width: 2,
                },
                {
                    name: "电话",
                    key: "phone",
                    width: 2,
                },
                {
                    name: "地址",
                    key: "area",
                    width: 2,
                },
                {
                    name: "分享码",
                    key: "shreCode",
                },
                {
                    name: "创建时间",
                    key: "cre_time",
                    width: 3,
                },
            ],
            companyList: [],
            isDialog: false,
            codeImg: "",
            code: null,
            codeId: "",
            address:"点击选择地址",
            longitude:"",
            latitude:"",
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress:false
        };
    },
    mounted() {
        this.getCompanyList();
    },
    methods: {
        //获取公司列表
        getCompanyList() {
            let {
                page,
                pageSize,
                searchCompany,
                searchAccount,
                searchPhone,
                searchNick,
                searchArea,
            } = this;
            post(Url.getCompanyList, {
                agencyphone: "",
                current: page,
                pagesize: pageSize,
                fist_time: "",
                last_time: "",
                nick: searchNick,
                account: searchAccount,
                area: searchArea,
                name: searchCompany,
                phone: searchPhone,
                status: 0,
            }).then((res) => {
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].cre_time = Tool.styTime(list[i].cre_time);
                    }
                    this.companyList = list;
                } else {
                    this.showToast("公司信息获取失败!");
                }
            });
        },
        //对话框显示隐藏
        dialogStatus(type) {
            this.isDialog = type === 1 ? true : false;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //获取验证码
        getVerifyCode() {
            this.showLoading();
            fullGet("http://uac.ynyqkj.net/api/v1/", Url.getVerifyCode, {
                codelength: 4,
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    this.codeImg = "data:image/jpeg;base64," + res.data.byteimage.fileContents;
                    this.codeId = res.data.cid;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //确认对话框
        enterDialog() {
            this.showLoading();
            this.isDialog = false;
            let {
                account,
                company,
                manager,
                phone,
                email,
                address,
                password,
                code,
                codeId,
                longitude,
                latitude
            } = this;
            post(Url.createCompany, {
                shreCode: "",
                account,
                name: company,
                nick: manager,
                phone,
                email,
                area:address,
                longitude,
                latitude,
                pwd: password,
                surepwd: password,
                verifyCode: code,
                verifyID: codeId,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getCompanyList();
                }
            });
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //显示地址选择框
        showAddress(){
            this.isAddress = true;
        },
        //取消地址选择
        hideAddress(){
            this.isAddress = false;
        },
        //获取所选地址
        getAddress(res){
            this.isAddress = false;
            this.address = res.address;
            this.longitude = res.longitude + "";
            this.latitude = res.latitude + "";
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule{
    margin-bottom:20px;
    width:320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    margin: 15px;
}
.add-item{
    margin-bottom:20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width:200px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius:4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
</style>