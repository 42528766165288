<template>
    <div class="address center" :class="[isAddress ? 'addressActive' : '']">
        <div class="address-box">
            <header class="address-header">
                <p class="text-20 ai blue">地址选择</p>
            </header>
            <div class="address-content start wrap">
                <div class="address-item">
                    <y-select
                        :data="provinceData"
                        defaultText="选择省/直辖市"
                        @getValue="getValue($event,0)"
                    ></y-select>
                </div>
                <div class="address-item" v-if="cityData.length > 0">
                    <y-select
                        :data="cityData"
                        defaultText="选择市/州"
                        @getValue="getValue($event,1)"
                    ></y-select>
                </div>
                <div class="address-item" v-if="areaData.length > 0">
                    <y-select
                        :data="areaData"
                        defaultText="选择区/县"
                        @getValue="getValue($event,2)"
                    ></y-select>
                </div>
                <div class="address-item" v-if="countryData.length > 0">
                    <y-select
                        :data="countryData"
                        defaultText="选择街道办/村镇"
                        @getValue="getValue($event,3)"
                    ></y-select>
                </div>
                <div class="address-item" v-if="streeData.length > 0">
                    <y-select
                        :data="streeData"
                        defaultText="选择街道"
                        @getValue="getValue($event,4)"
                    ></y-select>
                </div>
            </div>
            <footer class="dialog-footer end">
                <div class="default right" @click="cancel">取消</div>
                <div class="button" @click="enter">确定</div>
            </footer>
        </div>
    </div>
</template>
<script>
import YSelect from "@components/YSelect.vue";
import { fullGet } from "@util/http.js";

export default {
    name: "address",
    props: {
        isAddress: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        YSelect,
    },
    data() {
        return {
            level: 0,
            provinceData: [],
            cityData: [],
            areaData: [],
            countryData: [],
            streeData: [],
            selectArr: [],
            addressData:[],
            longitude:null,
            latitude:null
        };
    },
    mounted() {
        this.getAddress();
    },
    methods: {
        //获取地区数据
        getAddress() {
            let url = "http://pubserver.ynyqkj.net/api/v1/",
                api = "GetAddress/GetAdress",
                {selectArr,level } = this;
            fullGet(url, api, {
                Province: level > 0 ? selectArr[0] : "",
                City: level > 1 ? selectArr[1] : "",
                Area: level ? selectArr[2] : "",
                Country: level > 3 ? selectArr[3] : "",
            }).then((res) => {
                if (res.success) {
                    let data = res.data,
                        addressList = [];
                    for (let i = 0; i < data.length; i++) {
                        addressList.push(JSON.parse(data[i]));
                    }
                    this.addressData = addressList;
                    //组装数据
                    this.initData(addressList);
                }
            });
        },
        //组装数据
        initData(data) {
            let { level } = this,
                key = "",
                name = "";
            switch (level) {
                case 0:
                    key = "ProvinceFullName";
                    name = "provinceData";
                    break;
                case 1:
                    key = "CityFullName";
                    name = "cityData";
                    break;
                case 2:
                    key = "CountyFullName";
                    name = "areaData";
                    break;
                case 3:
                    key = "StreetFullName";
                    name = "countryData";
                    break;
                case 4:
                    key = "CommitteeFullName";
                    name = "streeData";
                    break;
                default:
                    break;
            }
            let addressList = [];
            for(let i = 0;i < data.length;i ++){
                addressList.push(data[i][key]);
            }
            this[name] = addressList;
        },
        //清理废旧数据
        clearData(i){
             switch (i) {
                case 0:
                    this.cityData = [];
                    this.areaData = [];
                    this.countryData = [];
                    this.streeData = [];
                    break;
                case 1:
                    this.areaData = [];
                    this.countryData = [];
                    this.streeData = [];
                    break;
                case 2:
                    this.countryData = [];
                    this.streeData = [];
                    break;
                case 3:
                    this.streeData = [];
                    break;
                default:
                    break;
            }
        },
        //选择地址
        getValue(e,level){
            let {value,index} = e;
            //获取经纬度
            this.getLocation(index);
            this.level = level + 1;
            //组装所选结果
            this.selectArr[level] = value;
            this.selectArr.length = level + 1;
            //清理废旧数据
            this.clearData(level);
            if(level >= 4) return;
            this.getAddress();
        },
        //获取经纬度
        getLocation(i){
            let {addressData} = this;
            this.longitude = addressData[i].Longitude;
            this.latitude = addressData[i].Latitude;
        },
        //取消
        cancel() {
            this.$emit("cancel");
        },
        //确定
        enter() {
            let {selectArr,longitude,latitude} = this,
                address = "";
            for(let i = 0;i < selectArr.length;i ++){
                address += selectArr[i];
            }
            this.$emit("enter",{
                addressData:selectArr,
                address,
                longitude,
                latitude
            });
        },
    },
};
</script>
<style scoped>
.address {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    transition: background-color 0.2s;
}
.addressActive {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.address-box {
    background-color: #ededed;
    border-radius: 4px;
}
.address-header{
    margin-bottom:30px;
    padding:20px 0 20px 15px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    background-color:#fafafa;
}
.address-content {
    padding: 15px;
    width: 520px;
    height:100px;
}
.address-item{
    margin-right:12px;
}
.dialog-footer {
    margin-top: 10px;
    padding: 15px;
}
</style>