<template>
    <section class="search-container">
        <div class="button bottom right" @click="showAddUser(1)">
            <span class="left">新增人员</span>
        </div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'village')"
                ></y-select>
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">姓名:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入姓名"
                    v-model="searchName"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">手机号:</span>
                <input
                    class="search-input"
                    type="number"
                    placeholder="请输入手机号"
                    v-model="searchPhone"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button right" @click="getUserList">查询</div>
            <div class="default left" @click="getUserList">
                <span class="iconfont i-reset"></span>
                <span class="left">刷新</span>
            </div>
        </div>
    </section>
    <div class="table-box">
        <div class="table column">
            <ul class="table-header around">
                <li class="table-item ai text-15">人像</li>
                <li class="table-item ai text-15">姓名</li>
                <li class="table-item ai text-15">电话</li>
                <li class="table-item ai text-15">小区</li>
                <li class="table-item ai text-15">区号</li>
                <li class="table-item ai text-15">楼栋号</li>
                <li class="table-item ai text-15">单元号</li>
                <li class="table-item ai text-15">户号</li>
                <li class="table-item ai text-15">是否启用人脸</li>
                <li class="table-item ai width-3 text-15">操作</li>
            </ul>
            <div class="table-container" v-if="userList.length > 0">
                <li
                    class="table-content"
                    v-for="(val, index) in userList"
                    :key="index"
                >
                    <ul class="content-item around">
                        <li class="table-item center">
                            <img
                                :src="val.user_face"
                                class="user-img"
                                alt=""
                                @click="showUserImg(val.user_face)"
                            />
                        </li>
                        <li class="table-item center">
                            <span>{{ val.realname }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.mobile }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.polt_name }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.district }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.buildingnum }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.elementnumber }}</span>
                        </li>
                        <li class="table-item center">
                            <span>{{ val.doorno }}</span>
                        </li>
                        <li class="table-item center">
                            <y-switch
                                :status="val.isFace"
                                @change="isUseFace(val, status)"
                            ></y-switch>
                        </li>
                        <li class="table-item width-3 center">
                            <div
                                class="button right"
                                @click="verifyUser(val, 1)"
                                v-if="val.state != 1"
                            >
                                通过
                            </div>
                            <div class="button right" @click="verifyUser(val, -2)" v-if="val.state != 1">不通过</div>
                            <div class="default" @click="verifyUser(val,-3)">删除</div>
                            <div class="button left" @click="dialogStatus(val)">分配分组</div>
                            <div class="button left" @click="showEditUser(val,1)">编辑</div>
                        </li>
                    </ul>
                </li>
            </div>
            <div class="no-box column center" v-else>
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </div>
    </div>
    <page-bar
        :page="page"
        :pageSize="pageSize"
        :total="total"
        @pageFunc="changePage"
    ></page-bar>
    <y-dialog
        :isDialog="isDialog"
        title="人员分组"
        @cancel="cencelDialog"
        @enter="enterDialog"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'selectVillage')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属分组:</span>
                    <y-select
                        :data="groupData"
                        defaultText="选择分组"
                        @getValue="getValue($event, 'selectGroup')"
                    ></y-select>
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isShowImg"
        title="人像大图"
        :enterBtn="false"
        @cancel="hideImg"
    >
        <template v-slot:content>
            <div class="center">
                <img :src="userImg" alt="" class="big-user-img" />
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isAddUser"
        title="新增人员"
        @cancel="showAddUser(2)"
        @enter="addUser"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'addVillage')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属楼栋:</span>
                    <y-select
                        :data="buildingData"
                        defaultText="选择楼栋"
                        @getValue="getValue($event, 'addBuilding')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属房号:</span>
                    <y-select
                        :data="roomData"
                        defaultText="选择房号"
                        @getValue="getValue($event, 'addRoom')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">名字:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入名字"
                        v-model="userName"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">身份证:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入身份证号码"
                        v-model="idCard"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">电话号码:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入电话号码"
                        v-model="phone"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">卡号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入卡号(可选)"
                        v-model="card_no"
                    />
                </div>
                <div class="add-item start bottom">
                    <span class="text-15 ai right">选择图片:</span>
                    <input
                        class="pointer"
                        id="faceFile"
                        type="file"
                        @change="selectImg"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isEditUser"
        title="编辑人员"
        @cancel="showEditUser({},2)"
        @enter="enterEditUser"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">名字:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入名字"
                        v-model="editUser.realname"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">身份证:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入身份证号码"
                        v-model="editUser.id_no"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">卡号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入卡号(可选)"
                        v-model="editUser.card_no"
                    />
                </div>
                <div class="add-item start bottom">
                    <span class="text-15 ai right">选择图片:</span>
                    <input
                        class="pointer"
                        id="editFaceFile"
                        type="file"
                        @change="selectImg"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import { post,get,fullPost } from "@util/http.js";
import Tool from "@util/tool.js";
import Url from "@util/url.js";
import PageBar from "@components/PageBar.vue";
import YSwitch from "@components/YSwitch.vue";

const OSS = require('ali-oss');

export default {
    name: "user",
    components: {
        YDialog,
        Toast,
        YSelect,
        Loading,
        PageBar,
        YSwitch,
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total: 0,
            searchVillage: "",
            searchName: "",
            searchPhone: null,
            faceState: "",
            groupData: [],
            groupList: [],
            group: "",
            user: {},
            userList: [],
            villageList: [],
            villageData: [],
            villageManageList: [],
            village: "",
            building: "",
            unitName: "",
            familyNum: "",
            area: null,
            isShowImg: false,
            userImg: "",
            isDialog: false,
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
            isAddUser: false,
            ossClient:{},
            faceImg:{},
            userName:"",
            idCard:"",
            card_no:"",
            phone:"",
            faceImgUrl:"",
            addVillageId:"",
            buildingList: [],
            buildingData: [],
            buildingId:"",
            roomList:[],
            roomData:[],
            roomId:"",
            isWang:false,
            editUser:{},
            isEditUser:false
        };
    },
    mounted() {
        let account = window.localStorage.getItem('account');
        this.isWang = account === "13888042265" ? true : false;

        this.getAdminVillage();
    },
    methods: {
        //获取阿里云oss相关参数
        getAliOSS(){
            fullPost("http://wy.yiqiankeji.net/",Url.getAliOSS, {}).then((res) => {
                if (res) {
                    this.ossClient = new OSS({
                        region: "oss-cn-chengdu",
                        accessKeyId:res.accessKeyId,
                        accessKeySecret:res.accessKeySecret,
                        stsToken:res.securityToken,
                        bucket: "humanface"
                    });
                    this.ossClient.options.endpoint.protocol="https:";
                } else {
                    this.showToast("获取相关信息失败,请刷新重试!");
                }
            });
        },
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name,
                        ];
                    }
                    this.getAliOSS();
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //获取人员列表
        getUserList() {
            this.showLoading();
            let {
                page,
                pageSize,
                searchVillage,
                searchName,
                searchPhone,
                faceState,
            } = this;
            post(Url.getUserList, {
                pagination: {
                    total: 0,
                    pagesize: pageSize,
                    current: page,
                    fist_time: "",
                    last_time: "",
                },
                pid: searchVillage,
                realname: searchName,
                mobile: searchPhone,
                face_disable: faceState,
                type: -1,
                card_no: "",
                id_no: "",
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].isFace = list[i].face_disable === "Y" ? 2 : 1;
                    }
                    this.userList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        // 相关操作
        verifyUser(val, status) {
            this.showLoading();
            post(Url.verifyUser, {
                pid: val.polt_id,
                rid: val.rid,
                state: status,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("操作成功!");
                    this.getUserList();
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //启用禁用人脸
        isUseFace(val) {
            let isFace = val.face_disable === "N" ? "Y" : "N";
            this.showLoading();
            post(Url.isUseFace, {
                pid: val.polt_id,
                rid: val.rid,
                face_disable: isFace,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("操作成功!");
                    this.getUserList();
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //获取分组列表
        getGroupList() {
            this.showLoading();
            let { page, searchVillage } = this;
            post(Url.getGroup, {
                pagination: {
                    total: 15,
                    pagesize: 500,
                    current: page,
                    fist_time: "",
                    last_time: "",
                },
                group_name: "",
                pid: searchVillage,
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list,
                        groupData = [];
                    for (let i = 0; i < list.length; i++) {
                        groupData.push(list[i].group_name);
                    }
                    this.groupList = list;
                    this.groupData = groupData;
                } else {
                    this.showToast("分组信息获取失败!");
                }
            });
        },
        //对话框显示隐藏
        dialogStatus(val) {
            this.isDialog = true;
            this.user = val;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //分配人员分组
        enterDialog() {
            this.showLoading();
            this.isDialog = false;
            let { village, group, user } = this;
            post(Url.setUserGroup, {
                pid: village,
                group_id: group,
                staff_ids: [user.rid],
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
            });
        },
        //获取选择结果
        getValue(val, key) {
            let i = val.index;
            switch (key) {
                case "village":
                    this.searchVillage = this.villageList[val.index].plot_id;
                    break;
                case "selectVillage":
                    this.village = this.villageList[val.index].plot_id;
                    this.getGroupList();
                    break;
                case "selectGroup":
                    this.group = this.groupList[val.index].rid;
                    break;
                case "face":
                    if (i === 0) {
                        this.faceState = "";
                    } else if (i === 1) {
                        this.faceState = "N";
                    } else if (i === 2) {
                        this.faceState = "Y";
                    }
                    break;
                case "addVillage": //新增人员选择小区
                    let plotId = this.villageList[val.index].plot_id;
                    this.addVillageId = plotId;
                    this.getBuildList(plotId);
                    break;
                case "addBuilding": //新增人员选择楼栋
                    let {polt_id,id} = this.buildingList[val.index];
                    this.buildingId = id;
                    this.getRoomList(polt_id,id);
                    break;
                case "addRoom": //新增人员选择房间
                    this.roomId = this.roomList[val.index].kid;
                    break;
                default:
                    break;
            }
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //显示大图图片
        showUserImg(url) {
            if (url == "" || url == null) {
                this.showToast("人像图片不存在！");
                return;
            }
            this.userImg = url;
            this.isShowImg = true;
        },
        //隐藏头像图片
        hideImg() {
            this.isShowImg = false;
        },
        //改变页码
        changePage(page) {
            this.page = page;
            this.getUserList();
        },
        //显示新增人员
        showAddUser(type) {
            if(type === 1){
                this.isAddUser = true;
                this.userName = "";
                this.idCard = "";
                this.card_no = "";
                this.phone = "";
                this.faceImg = null;
                this.faceImgUrl = "";
            }else{
                this.isAddUser = false;
            }
        },
        addCardStatus(status){
            this.isAddCard = status === 1 ? true : false;
        },
        //获取楼栋信息
        getBuildList(pid){
            post(Url.getBuildingList,{
                current:1,
                pagesize:150,
                fist_time: "",
                last_time: "",
                pid,
                district: "",
                buildingnum: "",
                floors: 0
            }).then((res) => {
                if (res.success) {
                    this.buildingData = [];
                    let data = res.data.list;
                    if (data.length === 0) return;
                    for (let i = 0; i < data.length; i++) {
                        let building = `${data[i].district != "" ? data[i].district + "—" : ""}${data[i].buildingnum}`;
                        this.buildingData = [...this.buildingData,building];
                    }
                    this.buildingList = data;
                } else {
                    this.showToast("楼栋信息获取失败!");
                }
            });
        },
        //获取房间信息
        getRoomList(poltId,buildingId){
            post(Url.getHouseList,{
                pagination: {
                    current: 1,
                    pagesize:600,
                    fist_time: "",
                    last_time: ""
                },
                bid:buildingId,
                pid:poltId,
                elementnumber:"",
                doorno: ""
            }).then((res) => {
                if (res.success) {
                    this.roomData = [];
                    let data = res.data.list;
                    if (data.length === 0) return;
                    for (let i = 0; i < data.length; i++) {
                        let room = `${data[i].elementnumber != "" && data[i].elementnumber != null ? data[i].elementnumber + "—" : ""}${data[i].doorno}`
                        this.roomData = [...this.roomData,room];
                    }
                    this.roomList = data;
                } else {
                    this.showToast("房号信息获取失败!");
                }
            });
        },
        //选择图片
        selectImg() {
            let id = this.isEditUser ? "editFaceFile" : "faceFile",
                face = document.getElementById(id),
                path = face.value;
            Tool.compressImage(face.files[0]).then(res => {
                this.faceImg = res;
                this.putObject();
            })
        },
        //上传图片到阿里云
        async putObject () {
            try {
                let nowTime = new Date().getTime(),
                    roundNum = Math.floor(Math.random() * 1000).toString();
                const result = await this.ossClient.put(nowTime + roundNum + ".png",this.faceImg);
                if(result.res.status == 200){
                    this.faceImgUrl = result.url;
                    if(this.isEditUser) this.editUser.user_face = result.url;
                }else{
                    this.showToast("图片上传失败，请重试!");
                }
            } catch (e) {
                console.log(e);
            }
        },
        //新增人员
        addUser() {
            let{addVillageId,buildingId,roomId,userName,idCard,card_no,phone,faceImgUrl} = this;
            let param = Tool.blockVerify([{val:roomId,name:"户号"},{val:userName,name:"名字"},{val:idCard,name:"身份证"},
                {val:phone,name:"手机号"}])
            if(!param.res){
                this.showToast(param.msg);
                return;
            }
            if(!Tool.verifyIdCard(idCard)){
                this.showToast("身份证号码格式不正确！");
                return;
            }
            console.log(this);
            post(Url.addUser,{
                pid:addVillageId,
                rid: "",
                bid:buildingId,
                roomid:roomId,
                type: 0,
                iphone:phone,
                realname:userName,
                card_no,
                id_no:idCard,
                user_face:faceImgUrl
            }).then((res) => {
                if (res.status) {
                    this.showToast("用户新增成功!");
                    this.isAddUser = false;
                    this.page = 1;
                    this.searchVillage = addVillageId;
                    this.getUserList();
                } else {
                    this.showToast("新增用户失败!");
                }
            });
        },
        //显示编辑人员
        showEditUser(val,type){
            if(type === 1){
                this.isEditUser = true;
                this.editUser = val;
            }else{
                this.isEditUser = false;
            }
        },
        //确认编辑人员
        enterEditUser(){
            let{editUser} = this;
            post(Url.editUser,{
                pid:editUser.polt_id,
                rid:editUser.rid,
                type:0,
                realname:editUser.realname,
                card_no:editUser.card_no,
                id_no:editUser.id_no,
                user_face:editUser.user_face
            }).then((res) => {
                if (res.status) {
                    this.showToast("用户编辑成功!");
                    this.isEditUser = false;
                    this.page = 1;
                    this.searchVillage = editUser.polt_id;
                    this.getUserList();
                } else {
                    this.showToast("编辑用户失败!");
                }
            });
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.user-img {
    width: 50px;
    height: 50px;
    border-radius: 3px;
}
.big-user-img {
    height: 450px;
}
</style>