<template>
    <div class="mask center" v-show="isShow">
        <div class="column-center">
            <img class="loading-img" src="@img/loading.gif" alt="" />
            <span class="white text-12">加载中...</span>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        isShow:{
            default:false,
            type:Boolean
        }
    }
}
</script>
<style scoped>
    .mask{
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,.2);
        z-index:2;
    }
    .loading-img{
        margin-bottom:10px;
    }
</style>