<template>
    <div class="table column">
        <header class="table-header around">
            <li
                class="table-item ai text-15"
                :style="{ width: el.width * 90 + 'px' }"
                v-for="(el, i) in columns"
                :key="i"
            >
                {{ el.name }}
            </li>
        </header>
        <div class="table-container" v-if="listData.length > 0">
            <li
                class="table-content"
                v-for="(val, index) in listData"
                :key="index"
            >
                <ul class="content-item around">
                    <li
                        class="table-item center"
                        :style="{ width: item.width * 90 + 'px' }"
                        v-for="(item, j) in columns"
                        :key="j"
                    >
                        <span>{{ val[item.key] }}</span>
                    </li>
                </ul>
            </li>
        </div>
        <div class="no-box column center" v-else>
            <span class="iconfont i-noinfo"></span>
            <p class="text-12 gray">暂时没有信息~</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "table",
    props: {
        columns: Array,
        data: Array,
    },
    data() {
        return {
            listData: [],
        };
    },
    updated() {
        //重组列表数据
        let { columns, data } = this;
        //重组表头
        for (let a = 0; a < columns.length; a++) {
            if (!columns[a].width) {
                columns[a].width = 1;
            }
        }
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            let item = {};
            for (let j = 0; j < columns.length; j++) {
                item[columns[j].key] = data[i][columns[j].key];
            }
            arr.push(item);
        }
        this.listData = arr;
    },
};
</script>
<style>
.table {
    box-sizing: border-box;
    width:100%;
    background-color: #fff;
    border-radius: 5px;
    overflow-x: scroll;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
}
.table-header {
    padding: 20px 15px;
    background-color: #f8f3f3;
    color: #333;
}
.table-item {
    text-align: center;
}
.table-content {
    padding: 0 15px;
}
.table-content:hover {
    background-color: #fafafa;
    cursor: pointer;
}
.content-item {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.no-box{
    padding:25px 0;
}
.i-noinfo{
    color:#999;
    font-size:110px !important;
}
</style>
