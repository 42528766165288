import axios from 'axios';

const baseUrl = "http://wy.yiqiankeji.net/api/v1/";

const TIMEOUT = 12000;
function axioscfig(url) {
    let token = window.localStorage.getItem('token');
    if (token != '') {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    axios.defaults.withCredentials = true;
    var axiosconfig = axios.create({
        timeout: TIMEOUT,
        baseURL: url
    });
    axiosconfig.interceptors.response.use(response => {
        return response.data;
    }, err => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = "请求错误";
                    break;
                case 401:
                    err.message = "未授权访问";
                    break;
                case 403:
                    err.message = "禁止访问";
                    break;
                case 404:
                    err.message = "该接口不存在";
                    break;
                case 405:
                    err.message = "拒绝访问请求";
                    break;
                case 500:
                    err.message = "服务器内部错误";
                    break;
            }
            alert(err.message);
        }
        return false;
    });
    return axiosconfig;
}
//通用post方法
function post(api, data) {
    let posts = axioscfig(baseUrl);
    let axiosposts = posts({
        url: api,
        method: 'post',
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": '*',
            'Access-Control-Allow-Methods': '*'
        },
        changeOrigin: true,
        withCredentials: true,
        data
    });

    return axiosposts;
}
function fullPost(base,api, data) {
    let posts = axioscfig(base);
    let axiosposts = posts({
        url: api,
        method: 'post',
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": '*',
            'Access-Control-Allow-Methods': '*',
            'Accept':'/*',
            'Content-Type':'application/x-www-form-urlencoded'
        },
        changeOrigin: true,
        withCredentials: true,
        data
    });
    return axiosposts;
}
function get(api, data) {
    let posts = axioscfig(baseUrl);
    let param = '';
    if (typeof data !== 'undefined') {
        param = data;
    }
    let axiosposts = posts({
        url: api,
        method: 'get',
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": '*',
            'Access-Control-Allow-Methods': '*'
        },
        changeOrigin: true,
        withCredentials: true,
        params: param
    });
    return axiosposts;
}
function fullGet(base,api, data) {
    let posts = axioscfig(base);
    let param = '';
    if (typeof data !== 'undefined') {
        param = data;
    }
    let axiosposts = posts({
        url: api,
        method: 'get',
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": '*',
            'Access-Control-Allow-Methods': '*'
        },
        changeOrigin: true,
        withCredentials: true,
        params: param
    });
    return axiosposts;
}

export { post, get,fullPost,fullGet }
