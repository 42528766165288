import { createApp } from 'vue';
import Home from '@page/home.vue';
import router from '@router/index.js';
import store from '@store/index.js';
import mitt from 'mitt';
import '@css/main.css';
import '@css/table.css';
import '@css/iconfont/iconfont.css';

const app = createApp(Home);

app.use(router);
app.use(store);
app.config.globalProperties.$mybus = new mitt();

app.mount('#app');
