<template>
    <div class="toast-box" :class="{toastActive : isShow}" v-show="isShow">
        <p class="toast-text">{{text}}</p>
    </div>
</template>
<script>
export default {
    props:{
        isShow:{
            default:false,
            type:Boolean
        },
        text:{
            default:"操作失败，请稍后重试!",
            type:String
        }
    }
}
</script>
<style scoped>
    .toast-box{
        position:fixed;
        left:50%;
        top:48%;
        padding:0;
        background-color:#fff;
        border-radius:5px;
        overflow:hidden;
        z-index:2;
        box-shadow:0 0 10px 5px rgba(0,0,0,.05);
        transition:animation .4s;
    }
    .toastActive{
        animation:toastTop .4s;
        padding:15px 30px;
    }
    @keyframes toastTop{
		0%{
			top:100%;
		}
		43%{
			top:46%;
		}
		76%{
			top:50%;
		}
		100%{
			top:48%;
		}
	}
</style>