<template>
    <div class="select" @click="changeShow()">
        <div class="between">
            <p class="l-gray default-text" :title="result">{{
                result == "" ? defaultText : result
            }}</p>
            <span class="iconfont i-row row-trans text-12" :class="[isShow ? 'rowActive' : '']" v-if="data.length > 0"></span>
        </div>
        <div class="select-list" :class="[isShow ? 'selectActive' : '']">
            <li
                class="select-box"
                v-for="(el, i) in data"
                :key="i"
                @click.stop="getValue(el, i)"
            >
                <div class="select-item">{{ el }}</div>
            </li>
        </div>
    </div>
</template>
<script>
export default {
    name: "y-select",
    props: {
        data: {
            default: [],
            type: Array,
        },
        defaultText:{
            default:"点击选择",
            type:String
        }
    },
    data() {
        return {
            isShow: false,
            result:""
        };
    },
    methods: {
        //是否显示下拉
        changeShow() {
            if (this.data.length <= 0) return;
            this.isShow = !this.isShow;
        },
        getValue(el, i) {
            this.result = el;
            this.isShow = false;
            this.$emit("getValue", {
                value: el,
                index:i
            });
        },
    },
};
</script>
<style scoped>
.select {
    position: relative;
    padding: 8px 10px;
    width: 140px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.03);
    cursor: pointer;
}
.row-trans{
    transform:rotate(180deg);
    transition:transform .3s;
}
.rowActive{
    transform:rotate(0);
}
.select-list {
    z-index:2;
    position: absolute;
    top: 36px;
    left: 0;
    width: 160px;
    max-height: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
    overflow-y: scroll;
    transition: max-height 0.2s;
}
.default-text{
    margin-right:3px;
    height:20px;
    overflow:hidden;
}
.selectActive {
    padding-bottom: 5px;
    max-height: 190px;
}
.select-box {
    padding: 0 10px;
}
.select-box:hover {
    background-color: #ededed;
    cursor: pointer;
}
.select-item {
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    text-align: center;
}
</style>