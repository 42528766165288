<template>
    <section class="search-container">
        <div class="button bottom" @click="addVillage">新增小区</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai">上级公司账号:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入上级公司账号"
                    v-model="searchAccount"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">电话号码:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入电话号码"
                    v-model="searchPhone"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">小区名称:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入小区名称"
                    v-model="searchName"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">地址:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入小区地址"
                    v-model="searchAdress"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getVillageList">查询</div>
        </div>
    </section>
    <div class="box-list" v-if="villageList.length > 0">
        <li class="box" v-for="(val, index) in villageList" :key="index">
            <div class="box-container">
                <div class="box-header between">
                    <span class="text-18 blue">{{ val.nick }}</span>
                    <span class="iconfont text-20 i-del pointer" @click="delVillage(val)"></span>
                </div>
                <div class="box-content">
                    <div class="box-item start">
                        <span class="box-name">小区ID:</span>
                        <span class="text-13 box-text">{{ val.tenantID }}</span>
                    </div>
                    <div class="box-item start-top">
                        <span class="box-name">地址:</span>
                        <p class="text-13 box-text box-address">
                            {{ val.area }}
                        </p>
                    </div>
                    <div class="box-item start">
                        <span class="box-name">创建时间:</span>
                        <span class="text-13 box-text">{{ val.cre_time }}</span>
                    </div>
                    <div class="box-footer between">
                        <span class="box-name">管理员:</span>
                        <span
                            class="iconfont i-row text-15 blue box-row"
                            :class="[val.isShowAdmin ? 'rowActive' : '']"
                            @click="showVillageAdmin(val, index)"
                        ></span>
                        <div
                            class="box-admin"
                            v-if="val.admin && val.isShowAdmin"
                        >
                            <ul class="column">
                                <li
                                    class="right bottom text-13 start"
                                    v-for="(el, i) in val.admin"
                                    :key="i"
                                >
                                    <span class="iconfont right i-close red text-20 pointer" v-if="isDelAdmin" @click="delAdmin(val,el)"></span>
                                    <span class="iconfont right i-user blue text-15"></span>
                                    <span>{{ el.realName }}({{ el.nUmber}})</span>
                                </li>
                            </ul>
                            <p
                                class="text-12 m-gray bottom center"
                                v-if="val.admin.length === 0"
                            >
                                暂无管理员
                            </p>
                            <div class="end top">
                                <div class="default right" v-if="val.admin.length > 0" @click="showDelAdmin(val,index)">
                                    {{isDelAdmin ? "完成" : "删除管理员"}}
                                </div>
                                <div
                                    class="button"
                                    @click="showAddAdmin(val, index)"
                                >
                                    添加管理员
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
    <no-info v-else></no-info>
    <page-bar :page="page" :pageSize="pageSize" :total="total" @pageFunc="changePage"></page-bar>
    <y-address
        :isAddress="isAddress"
        @cancel="hideAddress"
        @enter="getAddress"
    ></y-address>
    <y-dialog
        :isDialog="isDialog"
        :title="isBindAdmin ? '添加管理员' : '添加小区'"
        @cancel="cencelDialog"
        @enter="enterDialog"
    >
        <template v-slot:content>
            <div class="add-box" v-if="!isBindAdmin">
                <div class="add-item start">
                    <span class="ai text-15 add-name">小区名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入小区名称"
                        v-model="villageName"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">地区:</span>
                    <span class="text-15 pointer" @click="showAddress">{{
                        address
                    }}</span>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">详细地址:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入详细地址"
                        v-model="detailAddress"
                    />
                </div>
            </div>
            <div class="bind-admin start wrap" v-else>
                <li
                    class="bind-item start"
                    v-for="(el, i) in adminList"
                    :key="i"
                    @click="selectName(i)"
                >
                    <span
                        class="iconfont i-select blue text-20"
                        v-if="el.isSelect"
                    ></span>
                    <span class="iconfont i-no-select text-20" v-else></span>
                    <span class="text-14 admin-name"
                        >{{ el.realName }} ({{ el.phone }})</span
                    >
                </li>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YAddress from "@components/Address.vue";
import NoInfo from "@components/NoInfo.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from '@components/PageBar.vue';

export default {
    name: "gv",
    components: {
        YDialog,
        Toast,
        Loading,
        YAddress,
        NoInfo,
        PageBar
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total:0,
            searchAccount: "",
            searchPhone: "",
            searchNick: "",
            searchName: "",
            searchAddress: "",
            isDatePicker: false,
            defaultSwitch: true,
            adminList: [],
            villageList: [],
            village: {},
            isDialog: false,
            villageName: "",
            address: "点击选择地址",
            detailAddress:"",
            longitude: "",
            latitude: "",
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
            isBindAdmin: false,
            bindIndex: null,
            nameList: [],
            villageIndex: null,
            isDelAdmin:false
        };
    },
    mounted() {
        this.getVillageList();
    },
    methods: {
        //获取小区列表
        getVillageList() {
            this.showLoading();
            let {
                page,
                pageSize,
                searchAccount,
                searchPhone,
                searchName,
                searchNick,
                searchAddress,
            } = this;
            post(Url.getVillageList, {
                account: searchAccount,
                current: page,
                pagesize: pageSize,
                fist_time: "",
                last_time: "",
                phone: searchPhone,
                nick: searchNick,
                adress: searchAddress,
                name: searchName,
                status: 0,
                cre_time: "",
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].cre_time = Tool.styTime(list[i].cre_time);
                        list[i].isShowAdmin = false;
                        list[i].isAdmin = false;
                        list[i].admin = [];
                    }
                    this.villageList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast("小区信息获取失败!");
                }
            });
        },
        //获取管理员列表
        getAdminList(val) {
            this.showLoading();
            post(Url.getManageList, {
                pagination: {
                    current: 1,
                    pagesize: 100,
                    fist_time: "",
                    last_time: "",
                },
                account: "",
                polt_id: val.tenantID,
                poltadmin_name: "",
                poltadmin_phone: "",
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list,
                        arr = [];
                    for (let i = 0; i < list.length; i++) {
                        if (!list[i].isAgent) {
                            list[i].isSelect = false;
                            arr.push(list[i]);
                        }
                    }
                    this.adminList = arr;
                } else {
                    this.showToast("管理员信息获取失败!");
                }
            });
        },
        //对话框显示隐藏
        addVillage() {
            this.isDialog = true;
            this.isBindAdmin = false;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
            this.nameList = [];
        },
        //确认对话框
        enterDialog() {
            if (this.isBindAdmin) {
                this.bindAdmin();
            } else {
                this.createVillage();
            }
        },
        //创建小区
        createVillage() {
            this.showLoading();
            this.isDialog = false;
            let { address, villageName,detailAddress, longitude, latitude } = this;
            post(Url.createVillage, {
                nick: villageName,
                area: address + detailAddress,
                longitude,
                latitude,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getVillageList();
                }
            });
        },
        //删除小区
        delVillage(val) {
            this.showLoading();
            this.isDialog = false;
            post(Url.delVillage, {
                pid:val.tenantID
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getVillageList();
                }
            });
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //显示地址选择框
        showAddress() {
            this.isAddress = true;
        },
        //取消地址选择
        hideAddress() {
            this.isAddress = false;
        },
        //获取所选地址
        getAddress(res) {
            this.isAddress = false;
            this.address = res.address;
            this.longitude = res.longitude + "";
            this.latitude = res.latitude + "";
        },
        //添加管理员
        showAddAdmin(val, i) {
            this.isDialog = true;
            this.isBindAdmin = true;
            this.village = val;
            this.villageIndex = i;
            this.getAdminList(val);
        },
        //选择管理员
        selectName(i) {
            let select = this.adminList[i].isSelect,
                list = this.nameList;
            if (!select) {
                list.push(this.adminList[i].tenantID);
                this.nameList = list;
            } else {
                let id = this.adminList[i].tenantID,
                    index = list.indexOf(id);
                if (index > -1) {
                    list.splice(index, 1);
                    this.nameList = list;
                }
            }
            this.adminList[i].isSelect = !select;
        },
        //显示小区管理员
        showVillageAdmin(val, i) {
            let { villageList } = this;
            this.villageList[i].isShowAdmin = !villageList[i].isShowAdmin;
            if (!villageList[i].isAdmin) {
                this.getVillageAdmin(val, i);
            }
        },
        //获取对应小区管理员
        getVillageAdmin(val, i) {
            this.showLoading();
            post(Url.getVillageAdmin, {
                pagination: {
                    current: 1,
                    pagesize: 100,
                    fist_time: "",
                    last_time: "",
                },
                polt_id: val.tenantID,
                poltadmin_name: "",
                poltadmin_phone: "",
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    this.villageList[i].isAdmin = true;
                    this.villageList[i].admin = res.data.list;
                }
            });
        },
        //编辑管理员
        edit(village,villageIndex,list,type){
            this.showLoading();
            this.isDialog = false;
            post(Url.bindAdmin, {
                pid:village.tenantID,
                types:type,
                poldAdminIDs:list,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("操作成功!");
                    this.getVillageAdmin(village, villageIndex);
                    //清空所选管理员
                    if(type === 0){
                        this.nameList = [];
                        let adminList = this.adminList;
                        for (let i = 0; i < adminList.length; i++) {
                            adminList[i].isSelect = false;
                        }
                        this.adminList = adminList;
                    }
                } else {
                    this.showToast("操作失败！");
                }
            });
        },
        //绑定管理员
        bindAdmin() {
            let { village, nameList, villageIndex } = this;
            this.edit(village,villageIndex,nameList,0);
        },
        //切换管理员删除状态
        showDelAdmin(val,i){
            this.isDelAdmin = !this.isDelAdmin;
            this.village = val;
            this.villageIndex = i;
        },
        //删除管理员
        delAdmin(val,el){
            let { village,villageIndex } = this,
                nameList = [];
            nameList.push(el.tenantID);
            this.edit(village,villageIndex,nameList,1);
        },
        //改变页码
        changePage(page){
            this.page = page;
            this.getVillageList();
        }
    }
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    margin: 15px;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.bind-admin {
    width: 800px;
}
.bind-item {
    margin: 0 20px 15px 0;
}
.admin-name {
    margin-left: 8px;
}
.box-list {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    min-height:calc(100% - 550px);
    overflow-y: scroll;
    font-size: 0;
}
.box {
    display: inline-block;
    margin: 0 15px 15px 0;
}
.box-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
    font-size: 14px;
    transition: all 0.3s;
}
.box-container:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08),
        0 0 10px 5px rgba(0, 0, 0, 0.06) inset;
}
.box-header {
    padding: 10px;
    background-color: #f8f3f3;
    font-family: "ai";
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    font-size: 16px;
}
.box-content {
    padding: 15px 12px;
}
.box-item {
    margin-bottom: 15px;
}
.box-name {
    display: inline-block;
    width: 75px;
    font-size: 15px;
    font-weight: 600;
}
.box-text {
    display: inline-block;
    width: 250px;
}
.box-row {
    transform: rotate(180deg);
    transition: transform 0.2s;
    cursor: pointer;
}
.rowActive {
    transform: rotate(0);
}
.box-footer {
    position: relative;
}
.box-admin {
    position: absolute;
    top: 20px;
    left: -12px;
    padding: 10px;
    width: 329px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    background-color: #fff;
}
.box-address {
    height: 35px;
}
</style>