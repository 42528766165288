<template>
    <div class="dialog center" :class="[isDialog ? 'dialogActive' : '']">
        <div class="dialog-box">
            <header class="dialog-header start">
                <span class="ai text-20">{{title}}</span>
            </header>
            <section class="dialog-content">
                <slot name="content"></slot>
            </section>
            <footer class="dialog-footer end">
                <div class="default right" @click="cancel">取消</div>
                <div class="button" @click="enter" v-if="enterBtn">确定</div>
            </footer>
        </div>
    </div>
</template>
<script>
export default {
    name:"dialog",
    props:{
        isDialog:{
            default:false,
            type:Boolean
        },
        title:{
            default:"操作",
            type:String
        },
        enterBtn:{
            default:true,
            type:Boolean
        }
    },
    methods:{
        //取消
        cancel(){
            this.$emit('cancel');
        },
        //确定
        enter(){
            this.$emit('enter');
        }
    }
}
</script>
<style scoped>
    .dialog{
        z-index:1;
        position:fixed;
        top:0;
        left:0;
        width:0;
        height:100%;
        background-color:rgba(0,0,0,0);
        overflow:hidden;
        transition:background-color .2s;
    }
    .dialogActive{
        width:100%;
        background-color:rgba(0,0,0,.5);
    }
    .dialog-box{
        background-color:#fff;
        border-radius:4px;
    }
    .dialog-header{
        padding:12px 15px;
        border-bottom:1px solid rgba(0,0,0,.04);
        color:#4781ff;
    }
    .dialog-content{
        padding:15px;
        min-width:400px;
    }
    .dialog-footer{
        margin-top:10px;
        padding:15px;
    }
</style>