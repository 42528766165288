<template>
    <section class="search-container">
        <div class="start">
            <div class="button bottom right" @click="showDevice">新增设备</div>
        </div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai">设备名称:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入设备名称"
                    v-model="searchName"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">设备SN:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入设备SN"
                    v-model="searchSn"
                />
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'searchVillage')"
                ></y-select>
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai right">出口/入口:</span>
                <y-select
                    :data="['全部', '出口', '入口']"
                    @getValue="getSearchVal($event, 'entry')"
                ></y-select>
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai right">是否在线:</span>
                <y-select
                    :data="['全部', '在线', '离线']"
                    @getValue="getSearchVal($event, 'online')"
                ></y-select>
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getDeviceList">查询</div>
        </div>
    </section>
    <div class="box-list" v-if="deviceList.length > 0">
        <li class="box" v-for="(val, index) in deviceList" :key="index">
            <div class="box-container">
                <div class="box-header between">
                    <span class="text-18 blue">{{ val.name }}</span>
                    <span
                        class="iconfont text-25"
                        :class="[
                            val.online_state === 'Y' ? 'i-online' : 'i-outline',
                            val.online_state === 'Y' ? 'blue' : '',
                        ]"
                        :title="[
                            val.online_state === 'Y'
                                ? '设备在线中'
                                : '设备已离线',
                        ]"
                    ></span>
                </div>
                <div class="box-content">
                    <div class="box-item start bottom">
                        <span class="box-name">设备ID:</span>
                        <span class="text-13 box-text">{{ val.rid }}</span>
                    </div>
                    <div class="box-item start bottom">
                        <span class="box-name">设备SN:</span>
                        <span class="text-13 box-text">{{ val.sn }}</span>
                    </div>
                    <div class="between bottom">
                        <div class="box-item start">
                            <span class="box-name">所属小区:</span>
                            <span class="text-13">{{ val.plot_Name }}</span>
                        </div>
                        <div class="box-item start">
                            <span class="box-name">活体检测:</span>
                            <span class="text-13">{{
                                val.live_detect === "Y" ? "需要" : "不需要"
                            }}</span>
                        </div>
                    </div>
                    <div class="between bottom">
                        <div class="box-item start">
                            <span class="box-name">多人人脸:</span>
                            <span class="text-13">{{
                                val.multi_detect === "Y" ? "需要" : "不需要"
                            }}</span>
                        </div>
                        <div class="box-item start">
                            <span class="box-name">重复刷脸:</span>
                            <span class="text-13"
                                >{{ val.repeat_time }} 秒</span
                            >
                        </div>
                    </div>
                    <div class="between bottom">
                        <div class="box-item start">
                            <span class="box-name">检测距离:</span>
                            <span class="text-13">{{
                                val.distance_detect == 0
                                    ? "不限制"
                                    : val.distance_detect + "米"
                            }}</span>
                        </div>
                        <div class="box-item start">
                            <span class="box-name">类型 :</span>
                            <span class="text-13">{{
                                val.multi_detect === "I" ? "入口" : "出口"
                            }}</span>
                        </div>
                    </div>
                    <div class="box-item start between bottom">
                        <span class="box-name">创建时间:</span>
                        <span class="text-13 box-text">{{ val.cre_time }}</span>
                    </div>
                    <div class="function-box bottom top">
                        <div class="around top wrap">
                            <!-- <div class="button" @click="resetDevice(val)">
                                <div class="center">
                                    <span class="iconfont i-reset text-17"></span>
                                    <span class="left">重置</span>
                                </div>
                            </div> -->
                            <div class="button" @click="restartDevice(val)">
                                <div class="center">
                                    <span
                                        class="iconfont i-restart text-17"
                                    ></span>
                                    <span class="left">重启</span>
                                </div>
                            </div>
                            <div class="button center" @click="openDoor(val)">
                                <div class="center">
                                    <span
                                        class="iconfont i-opendoor text-17"
                                    ></span>
                                    <span class="left">开门</span>
                                </div>
                            </div>
                            <div
                                class="default center"
                                @click="showEditDevice(val)"
                            >
                                <div class="center">
                                    <span class="iconfont i-set text-17"></span>
                                    <span class="left">编辑</span>
                                </div>
                            </div>
                            <!-- <div class="default center" @click="delDevice(val)">
                                <div class="center">
                                    <span class="iconfont i-del text-17"></span>
                                    <span class="left">删除</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
    <no-info v-else></no-info>
    <page-bar
        :page="page"
        :pageSize="pageSize"
        :total="total"
        @pageFunc="changePage"
    ></page-bar>
    <y-address
        :isAddress="isAddress"
        @cancel="hideAddress"
        @enter="getAddress"
    ></y-address>
    <y-dialog
        :isDialog="isDialog"
        title="添加设备"
        @cancel="cencelDialog"
        @enter="addDevice"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">设备名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入设备名称"
                        v-model="deviceName"
                    />
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">设备SN编号:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入设备SN编号"
                        v-model="deviceSN"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'village')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">活体检测:</span>
                    <y-select
                        :data="['需要', '不需要']"
                        defaultText="是否需要"
                        @getValue="getValue($event, 'live_detect')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">多人人脸检测:</span>
                    <y-select
                        :data="['需要', '不需要']"
                        defaultText="是否需要"
                        @getValue="getValue($event, 'multi_detect')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">宽度扫描:</span>
                    <y-select
                        :data="['需要', '不需要']"
                        defaultText="是否需要"
                        @getValue="getValue($event, 'wide_detect')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">通道类型:</span>
                    <y-select
                        :data="['入口', '出口']"
                        defaultText="入口/出口"
                        @getValue="getValue($event, 'entrance_type')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">检测距离:</span>
                    <y-select
                        :data="['不限制', '1米内', '2米内', '3米内']"
                        defaultText="选择距离"
                        @getValue="getValue($event, 'distance_detect')"
                    ></y-select>
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">识别精准度:</span>
                    <input
                        type="number"
                        class="add-input"
                        placeholder="数值越大越精准(0.1-0.9)"
                        v-model="face_threshold"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">重复刷脸间隔:</span>
                    <input
                        type="number"
                        class="add-input"
                        placeholder="时间间隔内不会重复刷脸(秒)"
                        v-model="repeat_time"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isEditDevice"
        title="编辑设备"
        @cancel="cencelDialog"
        @enter="editDevice"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">设备名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入设备名称"
                        v-model="deviceObj.name"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YAddress from "@components/Address.vue";
import YSelect from "@components/YSelect.vue";
import NoInfo from "@components/NoInfo.vue";
import { post, fullPost } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from "@components/PageBar.vue";

export default {
    name: "guard",
    components: {
        YDialog,
        Toast,
        Loading,
        YAddress,
        YSelect,
        NoInfo,
        PageBar,
    },
    data() {
        return {
            page: 1,
            pageSize: 15,
            total: 0,
            searchVillage: "",
            searchSn: "",
            searchAccount: "",
            searchPhone: "",
            searchNick: "",
            searchName: "",
            searchEntryType: "",
            searchOnline: "",
            hideAddress: "",
            deviceList: [],
            villageList: [],
            villageData: [],
            deviceName: "",
            deviceSN: "",
            villageId: "",
            live_detect: "",
            multi_detect: "",
            wide_detect: "",
            entrance_type: "",
            distance_detect: "",
            face_threshold: "",
            repeat_time: "",
            isDatePicker: false,
            isDialog: false,
            address: "点击选择地址",
            longitude: "",
            latitude: "",
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
            deviceObj: {},
            isEditDevice: false,
        };
    },
    mounted() {
        this.getDeviceList();
        this.getAdminVillage();
    },
    methods: {
        //获取设备列表
        getDeviceList() {
            this.showLoading();
            let {
                page,
                pageSize,
                searchName,
                searchSn,
                searchEntryType,
                searchOnline,
                searchVillage
            } = this;
            post(Url.getDeviceList, {
                pagination: {
                    current: page,
                    pagesize: pageSize,
                    fist_time: "",
                    last_time: "",
                },
                pid:searchVillage,
                name: searchName,
                type: "",
                sn: searchSn,
                cre_time: "",
                online_state: searchOnline,
                connect_state: "",
                reset_flag: 0,
                live_detect: "",
                multi_detect: "",
                repeat_time: 0,
                wide_detect: "",
                distance_detect: "",
                face_threshold: 0,
                entrance_type: searchEntryType,
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].cre_time = Tool.styTime(list[i].cre_time);
                        list[i].isShowAdmin = false;
                        list[i].isAdmin = false;
                        list[i].admin = [];
                    }
                    this.deviceList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast("获取失败!");
                }
            });
        },
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name,
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //新增设备
        addDevice() {
            this.showLoading();
            let {
                deviceName,
                deviceSN,
                villageId,
                live_detect,
                multi_detect,
                wide_detect,
                entrance_type,
                distance_detect,
                face_threshold,
                repeat_time,
            } = this;
            post(Url.addDevice, {
                rid: "",
                plot_id: villageId,
                name: deviceName,
                sn: deviceSN,
                type: "F",
                live_detect,
                multi_detect,
                distance_detect: distance_detect.toString(),
                face_threshold: parseFloat(face_threshold),
                wide_detect,
                entrance_type,
                repeat_time: parseInt(repeat_time),
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("添加成功！");
                    this.getDeviceList();
                    this.isDialog = false;
                } else {
                    this.showToast("操作失败!");
                }
            });
        },
        showEditDevice(val) {
            this.deviceObj = val;
            this.isEditDevice = true;
        },
        //编辑设备
        editDevice() {
            this.showLoading();
            let {
                sn,
                rid,
                plot_id,
                type,
                name,
                live_detect,
                multi_detect,
                wide_detect,
                entrance_type,
                distance_detect,
                face_threshold,
                repeat_time,
            } = this.deviceObj;
            post(Url.addDevice, {
                rid,
                plot_id,
                name,
                sn,
                type,
                live_detect,
                multi_detect,
                distance_detect,
                face_threshold,
                wide_detect,
                entrance_type,
                repeat_time,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("修改成功！");
                    this.getDeviceList();
                    this.isEditDevice = false;
                } else {
                    this.showToast("操作失败!");
                }
            });
        },
        //设置新密码
        setNewPwd() {
            let { passurl, oldPass } = this.faceDevice,
                { newPwd } = this;
            this.showLoading();
            fullPost(passurl, "", {
                oldPass,
                newPass: newPwd,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("重置成功！");
                    this.processIndex = 2;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //重置设备
        resetDevice(val) {
            this.showLoading();
            post(Url.resetDevice, {
                param: {
                    rid: val.rid,
                    sn: val.sn,
                    reset_flag: 1,
                },
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("重置成功！");
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //重启设备
        restartDevice(val) {
            this.showLoading();
            post(Url.restartDevice, {
                param: {
                    rid: 0,
                    sn: val.sn,
                },
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("重启成功！");
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        // 开门
        openDoor(val) {
            this.showLoading();
            post(Url.openDoor, {
                obj: {
                    sn: val.sn,
                    userId: 0,
                },
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("开门成功！");
                } else {
                    this.showToast("开门失败!");
                }
            });
        },
        // 删除设备
        delDevice(val) {
            this.showLoading();
            post(Url.delDevice, {
                rid: val.rid,
                pid: val.plot_id,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("操作成功！");
                    this.getDeviceList();
                } else {
                    this.showToast("操作失败!");
                }
            });
        },
        //显示新增设备窗口
        showDevice() {
            this.isDialog = true;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
            this.isEditDevice = false;
        },
        //确认对话框
        enterDialog() {},
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //获取下拉框值
        getValue(e, key) {
            switch (key) {
                case "entrance_type": //进出口
                    this.entrance_type = e.index === 0 ? "I" : "O";
                    break;
                case "distance_detect": //检测距离
                    this.distance_detect = e.index;
                    break;
                case "searchVillage": //搜索设备选择小区
                    this.searchVillage = this.villageList[e.index].plot_id;
                    break;
                case "village": //小区
                    this.villageId = this.villageList[e.index].plot_id;
                    break;
                default:
                    //默认
                    this[key] = e.index === 0 ? "Y" : "N";
                    break;
            }
        },
        //获取搜索条件
        getSearchVal(e, key) {
            let i = e.index;
            switch (key) {
                case "entry": //进出口 I:入口 O:出口
                    if (i === 0) {
                        this.searchEntryType = "";
                    } else if (i === 1) {
                        this.searchEntryType = "O";
                    }
                    if (i === 2) {
                        this.searchEntryType = "I";
                    }
                    break;
                case "online": //是否在线
                    if (i === 0) {
                        this.searchOnline = "";
                    } else if (i === 1) {
                        this.searchOnline = "Y";
                    }
                    if (i === 2) {
                        this.searchOnline = "N";
                    }
                    break;
                default:
                    break;
            }
        },
        //改变页码
        changePage(page) {
            this.page = page;
            this.getDeviceList();
        },
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    margin: 15px;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.bind-admin {
    width: 800px;
}
.bind-item {
    margin: 0 20px 15px 0;
}
.admin-name {
    margin-left: 8px;
}
.box-list {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    overflow-y: scroll;
    font-size: 0;
}
.box {
    display: inline-block;
    /* position: relative; */
    margin: 0 15px 15px 0;
}
.box-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
    font-size: 14px;
    transition: all 0.3s;
}
.box-container:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08),
        0 0 10px 5px rgba(0, 0, 0, 0.06) inset;
}
.box-header {
    padding: 10px;
    background-color: #f8f3f3;
    font-family: "ai";
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}
.box-content {
    padding: 15px 12px;
}
.box-item {
    margin-bottom: 15px;
}
.function-box {
    margin: 25px 0 15px;
}
.box-name {
    display: inline-block;
    width: 85px;
    font-size: 14px;
    font-weight: 600;
}
.box-text {
    display: inline-block;
    width: 250px;
}
.box-row {
    transform: rotate(180deg);
    transition: transform 0.2s;
    cursor: pointer;
}
.rowActive {
    transform: rotate(0);
}
.box-footer {
    position: relative;
}
.box-admin {
    position: absolute;
    top: 20px;
    left: -12px;
    padding: 10px;
    width: 329px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
}
.box-address {
    height: 35px;
}
</style>