<template>
    <div class="column center no-info">
        <span class="iconfont i-noinfo"></span>
        <p class="top">暂时没有信息~</p>
    </div>
</template>
<script>
export default {
    name:"no-info",
    props:{

    },
    data(){
        return{

        }
    }
}
</script>
<style scoped>
    .no-info{
        padding:45px 0;
    }
    .i-noinfo{
        font-size:170px;
    }
</style>