<template>
    <section class="search-container">
        <div class="button bottom" @click="addGroup">新增分组</div>
        <section class="search-box top bottom start wrap">
            <div class="search-rule start bottom">
                <span class="text-15 ai right">所属小区:</span>
                <y-select
                    :data="villageData"
                    defaultText="选择小区"
                    @getValue="getValue($event, 'searchVillage')"
                ></y-select>
            </div>
            <div class="search-rule start bottom">
                <span class="text-15 ai">分组名称:</span>
                <input
                    class="search-input"
                    type="text"
                    placeholder="请输入分组名称"
                    v-model="searchName"
                />
            </div>
        </section>
        <div class="top bottom center">
            <div class="button left" @click="getGroupList">查询</div>
        </div>
    </section>
    <div class="box-list" v-if="groupList.length > 0">
        <li class="box" v-for="(val, index) in groupList" :key="index">
            <div class="box-container">
                <div class="box-header between">
                    <span class="text-18 blue">{{ val.group_name }}</span>
                    <!-- <span
                        class="iconfont i-del text-20 pointer"
                        @click="delGroup(val)"
                    ></span> -->
                </div>
                <div class="box-content">
                    <div class="box-item start">
                        <span class="box-name">分组id:</span>
                        <span class="text-13 box-text">{{ val.rid }}</span>
                    </div>
                    <div class="box-item start">
                        <span class="box-name">所属小区:</span>
                        <span class="text-13 box-text">{{ val.nick }}</span>
                    </div>
                    <div class="box-item between">
                        <span class="box-name">人员:</span>
                        <div class="button" @click="getGroupUser(val)">查看人员</div>
                    </div>
                    <div class="box-footer between">
                        <span class="box-name">门禁设备:</span>
                        <span
                            class="iconfont i-row text-15 blue box-row"
                            :class="[val.isShowDevice ? 'rowActive' : '']"
                            @click="showDevice(val, index)"
                        ></span>
                        <div
                            class="box-admin"
                            v-if="val.device && val.isShowDevice"
                        >
                            <ul class="column">
                                <li
                                    class="right bottom text-13 start"
                                    v-for="(el, i) in val.device"
                                    :key="i"
                                >
                                    <span class="iconfont right i-close red text-20 pointer" v-if="isDelDevice" @click="delGroupDevice(el)"></span>
                                    <span class="iconfont right i-iot blue text-15"></span>
                                    <span>{{ el.name }}({{ el.sn }})</span>
                                </li>
                            </ul>
                            <p
                                class="text-12 m-gray bottom center"
                                v-if="val.device.length === 0"
                            >
                                暂无设备
                            </p>
                            <div class="end top">
                                <div class="default right" v-if="val.device.length > 0" @click="showDelDevice">
                                    {{isDelDevice ? "完成" : "删除设备"}}
                                </div>
                                <div
                                    class="button"
                                    @click="showBindDevice(val, index)"
                                >
                                    添加设备
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </div>
    <no-info v-else></no-info>
    <page-bar :page="page" :pageSize="pageSize" :total="total" @pageFunc="changePage"></page-bar>
    <y-dialog
        :isDialog="isDialog"
        title="添加分组"
        @cancel="cencelDialog"
        @enter="createGroup"
    >
        <template v-slot:content>
            <div class="add-box">
                <div class="add-item start">
                    <span class="ai text-15 add-name">分组名称:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入分组名称"
                        v-model="groupName"
                    />
                </div>
                <div class="add-item start top">
                    <span class="ai text-15 add-name">所属小区:</span>
                    <y-select
                        :data="villageData"
                        defaultText="选择小区"
                        @getValue="getValue($event, 'village')"
                    ></y-select>
                </div>
                <div class="add-item start">
                    <span class="ai text-15 add-name">描述:</span>
                    <input
                        type="text"
                        class="add-input"
                        placeholder="请输入分组描述"
                        v-model="groupDetail"
                    />
                </div>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isDevice"
        title="绑定设备"
        @cancel="cancelDevice"
        @enter="enterBindDevice"
    >
        <template v-slot:content>
            <div class="bind-admin start wrap">
                <li
                    class="bind-item start"
                    v-for="(el, i) in deviceList"
                    :key="i"
                    @click="selectDeviceName(i)"
                >
                    <span
                        class="iconfont i-select blue text-20"
                        v-if="el.isSelect"
                    ></span>
                    <span class="iconfont i-no-select text-20" v-else></span>
                    <span class="text-14 admin-name">{{ el.name }}</span>
                </li>
            </div>
        </template>
    </y-dialog>
    <y-dialog
        :isDialog="isShowUser"
        :enterBtn="false"
        title="分组人员"
        @cancel="hideUser"
    >
        <template v-slot:content>
            <div class="between bottom">
                <div class="start">
                    <div class="search-rule start">
                        <span class="text-15 ai right">是否已分配:</span>
                        <y-select
                            :data="['未分配','已分配']"
                            defaultText="已分配"
                            @getValue="getValue($event, 'distribute')"
                        ></y-select>
                    </div>
                    <div class="search-rule start">
                        <span class="text-15 ai">姓名:</span>
                        <input
                            class="search-input"
                            type="text"
                            placeholder="请输入姓名"
                            v-model="searchNick"
                        />
                    </div>
                    <div class="button bottom" @click="searchUser">搜索</div>
                </div>
                <div class="button" @click="setUserGroup" v-if="isShowSetUser">分配到该分组</div>
            </div>
            <div class="bind-admin wrap">
                <li
                    class="bind-item start"
                    v-for="(el, i) in userList"
                    :key="i"
                >
                    <span class="iconfont text-20 pointer"
                        :class="[el.isSelect ? 'i-select' : 'i-no-select',el.isSelect ? 'blue' : '']"
                        @click="selectUser(el)"
                        v-if="noSetUser"
                    ></span>
                    <span class="iconfont i-del text-20 pointer" @click="delUser(el)" v-else></span>
                    <span class="text-14 admin-name">{{ el.realname == null ? 'IC卡' : el.realname}}({{el.mobile}})</span>
                </li>
            </div>
            <div class="start top" v-if="noSetUser">
                <span class="iconfont text-20 pointer"
                    :class="[allSelect ? 'i-select' : 'i-no-select',allSelect ? 'blue' : '']"
                    @click="allSelectUser"
                ></span>
                <span class="text-14 admin-name">全选本页</span>
            </div>
            <page-bar :page="userPage" :pageSize="userPageSize" :total="userTotal" @pageFunc="userChangePage"></page-bar>
            <div class="no-box column center" v-if="userList.length === 0">
                <span class="iconfont i-noinfo"></span>
                <p class="text-12 gray">暂时没有信息~</p>
            </div>
        </template>
    </y-dialog>
    <loading :isShow="isShowLoading" />
    <toast :isShow="isToast" :text="toastText" />
</template>
<script>
import YDialog from "@components/Dialog.vue";
import Loading from "@components/Loading.vue";
import Toast from "@components/Toast.vue";
import YSelect from "@components/YSelect.vue";
import NoInfo from "@components/NoInfo.vue";
import { post } from "@util/http.js";
import Url from "@util/url.js";
import Tool from "@util/tool.js";
import PageBar from '@components/PageBar.vue';

export default {
    name: "group",
    components: {
        YDialog,
        Toast,
        Loading,
        YSelect,
        NoInfo,
        PageBar
    },
    data() {
        return {
            page: 1,
            pageSize:15,
            total: 0,
            userPage:1,
            userPageSize:25,
            userTotal:0,
            searchVillage:"",
            searchName: "",
            villageData: [],
            villageList: [],
            villageId: "",
            groupList: [],
            userGroup:{},
            userState:2,
            noSetUser:false,
            isShowUser:false,
            userList:[],
            isDialog: false,
            groupName: "",
            groupDetail: "",
            deviceList: [],
            searchPhone:"",
            searchNick:"",
            isDevice: false,
            isShowSetUser:false,
            isDelDevice:false,
            allSelect:false,
            group: {},
            groupIndex: null,
            isShowLoading: false,
            isToast: false,
            toastText: "",
            isAddress: false,
        };
    },
    mounted() {
        this.getAdminVillage();
    },
    methods: {
        //获取分组列表
        getGroupList() {
            this.showLoading();
            let { page, pageSize,searchVillage,searchName } = this;
            post(Url.getGroup, {
                pagination: {
                    total: 15,
                    pagesize: pageSize,
                    current: page,
                    fist_time: "",
                    last_time: "",
                },
                group_name: searchName,
                pid:searchVillage
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].isShowDevice = false;
                        list[i].isDevice = false;
                        list[i].device = [];
                    }
                    this.groupList = list;
                    this.total = res.data.pagination.total;
                } else {
                    this.showToast("分组信息获取失败!");
                }
            });
        },
        //获取管理员所属小区列表
        getAdminVillage() {
            post(Url.getAdminVillage, {}).then((res) => {
                if (res.success) {
                    this.villageList = res.data;
                    if (res.data.length === 0) return;
                    for (let i = 0; i < res.data.length; i++) {
                        this.villageData = [
                            ...this.villageData,
                            res.data[i].plot_Name
                        ];
                    }
                } else {
                    this.showToast("所属小区获取失败!");
                }
            });
        },
        //搜索人员
        searchUser(){
            this.getGroupUser(this.userGroup);
        },
        //获取分组人员列表
        getGroupUser(val) {
            this.showLoading();
            this.userGroup = val;
            let {userPage,userPageSize,userState,searchNick,searchPhone} = this;
            post(Url.getGroupUser,{
                pagination: {
                    current:userPage,
                    pagesize:userPageSize,
                    fist_time: "",
                    last_time: ""
                },
                pid:val.plotid,
                rid:val.rid,
                state:userState,
                realname:searchNick,
                mobile:searchPhone,
                type: 0,
                card_no: "",
                id_no: ""
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    this.isShowUser = true;
                    let list = res.data.list;
                    if(userState === 1){
                        for(let i = 0;i < list.length;i ++){
                            list[i].isSelect = false;
                        }
                    }
                    this.noSetUser = userState === 1 ? true : false;
                    this.userList = list;
                    this.userTotal = res.data.pagination.total;
                } else {
                    this.showToast(res.msg);
                }
            });
        },
        //人员信息分页
        userChangePage(page){
            this.allSelect = false;
            this.userPage = page;
            this.getGroupUser(this.userGroup);
        },
        //选择人员
        selectUser(val){
            let {userList} = this;
            for(let i = 0;i < userList.length;i ++){
                if(userList[i].sid === val.sid){
                    this.userList[i].isSelect = !userList[i].isSelect;
                }
            }
            this.isShowSetUser = this.isSelectUser(this.userList);
        },
        //全选
        allSelectUser(){
            let {userList,allSelect} = this;
            for(let i = 0;i < userList.length;i ++){
                userList[i].isSelect = !allSelect;
            }
            this.userList = userList;
            this.allSelect = !allSelect;
        },
        //检测是否有选中人员
        isSelectUser(list){
            for(let i = 0;i < list.length;i ++){
                if(list[i].isSelect) return true;
            }
            return false;
        },
        //设置人员分组
        setUserGroup(){
            let {userList,userGroup} = this,userSelect = [];
            for(let i = 0;i < userList.length;i ++){
                if(userList[i].isSelect){
                    userSelect.push(userList[i].sid);
                }
            }
            this.showLoading();
            this.isDialog = false;
            post(Url.setUserGroup,{
                pid:userGroup.plotid,
                group_id:userGroup.rid,
                staff_ids:userSelect
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if(res.status){
                    this.getGroupUser(this.userGroup);
                    this.isShowSetUser = false;
                }
            });
        },
        //删除分组人员
        delUser(val){
            this.showLoading();
            this.isDialog = false;
            post(Url.delGroupUser, {
                pid:val.plot_id,
                group_id:val.groupid,
                staff_ids: [val.sid]
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getGroupUser(this.userGroup);
                }
            });
        },
        //隐藏分组人员
        hideUser(){
            this.isShowUser = false;
        },
        //对话框显示隐藏
        addGroup() {
            this.isDialog = true;
        },
        //隐藏对话框
        cencelDialog() {
            this.isDialog = false;
        },
        //创建小区
        createGroup() {
            let { villageId, groupName, groupDetail } = this;
            let isBlock = Tool.blockVerify([
                { val: villageId, name: "小区" },
                { val: groupName, name: "分组名称" },
                { val: groupDetail, name: "描述" },
            ]);
            if (!isBlock.res) {
                this.showToast(isBlock.msg);
                return;
            }
            this.showLoading();
            this.isDialog = false;
            post(Url.createGroup, {
                rid: "",
                pid: villageId,
                group_name: groupName,
                msg: groupDetail,
            }).then((res) => {
                this.hideLoading();
                if (res.status) {
                    this.showToast("创建成功！");
                    this.getGroupList();
                } else {
                    this.showToast("创建失败！");
                }
            });
        },
        //删除分组
        delGroup(val) {
            this.showLoading();
            this.isDialog = false;
            post(Url.delGroup, {
                key: val.rid,
                pid: val.plotid,
            }).then((res) => {
                this.hideLoading();
                this.showToast(res.msg);
                if (res.status) {
                    this.getGroupList();
                }
            });
        },
        //获取分组设备列表
        getDeviceList(val, i) {
            post(Url.getGroupDevice, {
                pagination: {
                    current: 1,
                    pagesize: 30,
                    fist_time: "",
                    last_time: "",
                },
                rid: val.rid,
                pid: val.plotid,
                state: 0,
                name: "",
                type: "",
                sn: "",
                cre_time: "",
                online_state: "",
                connect_state: "",
                reset_flag: 0,
                live_detect: "",
                multi_detect: "",
                repeat_time: 0,
                wide_detect: "",
                distance_detect: "",
                face_threshold: 0,
                entrance_type: "",
            }).then((res) => {
                if (res.success) {
                    this.groupList[i].isDevice = true;
                    this.groupList[i].device = res.data.list;
                } else {
                    this.showToast("设备信息获取失败!");
                }
            });
        },
        //显示加载动画
        showLoading() {
            this.isShowLoading = true;
        },
        //隐藏加载动画
        hideLoading() {
            this.isShowLoading = false;
        },
        //显示提示框
        showToast(text) {
            this.isToast = true;
            this.toastText = text;
            setTimeout(() => {
                this.isToast = false;
                this.toastText = "";
            }, 3000);
        },
        //取消绑定设备
        cancelDevice() {
            this.isDevice = false;
        },
        //获取所选小区
        getValue(e, key) {
            switch(key){
                case "village":
                    this.villageId = this.villageList[e.index].plot_id;
                    break;
                case "searchVillage":
                    this.searchVillage = this.villageList[e.index].plot_id;
                    break;
                case "distribute":
                    this.userState = e.index + 1;
                    break;
                default:
                    break;
            }
        },
        //显示设备
        showDevice(val, i) {
            let { groupList } = this;
            this.groupList[i].isShowDevice = !groupList[i].isShowDevice;
            if (!groupList[i].isDevice) {
                this.getDeviceList(val, i);
            }
        },
        //显示绑定设备
        showBindDevice(val, i) {
            this.isDevice = true;
            this.group = val;
            this.groupIndex = i;
            this.getDevices();
        },
        //获取所有设备列表
        getDevices() {
            this.showLoading();
            post(Url.getDeviceList, {
                pagination: {
                    current: 1,
                    pagesize: 30,
                    fist_time: "",
                    last_time: "",
                },
                name: "",
                type: "",
                sn: "",
                cre_time: "",
                online_state: "",
                connect_state: "",
                reset_flag: 0,
                live_detect: "",
                multi_detect: "",
                repeat_time: 0,
                wide_detect: "",
                distance_detect: "",
                face_threshold: 0,
                entrance_type: "",
            }).then((res) => {
                this.hideLoading();
                if (res.success) {
                    let list = res.data.list;
                    for (let i = 0; i < list.length; i++) {
                        list[i].isSelect = false;
                    }
                    this.deviceList = list;
                } else {
                    this.showToast("获取失败!");
                }
            });
        },
        //选择设备
        selectDeviceName(i) {
            let { deviceList } = this;
            this.deviceList[i].isSelect = !deviceList[i].isSelect;
        },
        //确认绑定设备
        enterBindDevice() {
            let { group, deviceList } = this,
                devices = [];
            for (let i = 0; i < deviceList.length; i++) {
                if (deviceList[i].isSelect) {
                    devices.push(deviceList[i].rid);
                }
            }
            if (devices.length === 0) {
                this.isDevice = false;
                return;
            }
            this.showLoading();
            this.isDialog = false;
            post(Url.bindDevice, {
                group_id: group.rid,
                device_ids: devices,
            }).then((res) => {
                this.hideLoading();
                if(res.code == 0) {
                    this.showToast("操作成功！");
                    this.getGroupList();
                    this.isDevice = false;
                }else{
                    this.showToast("操作失败！");
                }
            });
        },
        //显示隐藏设备删除按钮
        showDelDevice(){
            this.isDelDevice = !this.isDelDevice;
        },
        //确认删除设备
        delGroupDevice(val) {
            this.showLoading();
            post(Url.delGroupDevice,{
                group_id:val.group_id,
                device_ids: [val.rid]
            }).then((res) => {
                this.hideLoading();
                if(res.code === 0) {
                    this.showToast("操作成功！");
                    this.getGroupList();
                    this.isDelDevice = false;
                }else{
                    this.showToast("操作失败！");
                }
            });
        },
        //改变页码
        changePage(page){
            this.page = page;
            this.getGroupList();
        }
    },
};
</script>
<style scoped>
.search-container {
    margin: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
}
.search-rule {
    margin-bottom: 20px;
    width: 320px;
}
.search-input {
    margin: 0 25px 0 8px;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.table-box {
    margin: 15px;
}
.add-item {
    margin-bottom: 20px;
}
.add-name {
    display: inline-block;
    width: 80px;
}
.add-input {
    margin: 0 15px 0 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.code-tip {
    display: inline-block;
    margin-left: 8px;
    outline: #999;
    cursor: pointer;
}
.bind-admin {
    width: 1000px;
}
.bind-item {
    margin: 0 20px 15px 0;
}
.admin-name {
    margin-left: 8px;
}
.box-list {
    padding: 0 15px 25px;
    max-height: calc(100% - 290px);
    min-height:calc(100% - 550px);
    overflow-y: scroll;
    font-size: 0;
}
.box {
    display: inline-block;
    /* position: relative; */
    margin: 0 15px 15px 0;
}
.box-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
    font-size: 14px;
    transition: all 0.3s;
}
.box-container:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.08),
        0 0 10px 5px rgba(0, 0, 0, 0.06) inset;
}
.box-header {
    padding: 10px;
    background-color: #f8f3f3;
    font-family: "ai";
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}
.box-content {
    padding: 15px 12px;
}
.box-item {
    margin-bottom: 15px;
}
.function-box {
    margin: 25px 0 15px;
}
.box-name {
    display: inline-block;
    width: 75px;
    font-size: 15px;
    font-weight: 600;
}
.box-text {
    display: inline-block;
    width: 250px;
}
.box-row {
    transform: rotate(180deg);
    transition: transform 0.2s;
    cursor: pointer;
}
.rowActive {
    transform: rotate(0);
}
.box-footer {
    position: relative;
}
.box-admin {
    position: absolute;
    top: 20px;
    left: -12px;
    padding: 10px;
    width: 329px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
}
.box-address {
    height: 35px;
}
</style>